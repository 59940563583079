import { Icon } from "@iconify/react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Box, Center, HStack, VStack } from "../components/utils";
import { useEsopPlans } from "../queries";
import { usePermissionStore } from "../store/permissionStore";
import { EsopPlan } from "../types/EsopPlan";
import { _sum } from "../utils/arrays";
import { determineUserAccessToResource } from "../utils/auth";
import { getCurrencyType } from "../utils/currencyFormatter";
import { Action, Resource } from "../utils/interfaces/Companies";
import { getFormattedValue } from "../utils/string";
import { SemiDoughnutGraph } from "./SemiDoughnutChart";

function CardPool() {
  const { id } = useParams();
  const currency = getCurrencyType();
  const _id = id ?? "";
  const { data, isPlaceholderData } = useEsopPlans();
  const { permission } = usePermissionStore();
  const header = "Options Pool";
  const esopPlans = data || [];
  let plans: EsopPlan[] = [];
  if (_id === "") {
    plans = esopPlans.filter((plan) => plan.planName !== "Total");
  } else {
    plans = esopPlans.filter((plan) => plan.esopPlanId?.toString() === _id);
  }
  const totalOptions = _.sumBy(plans, (plan) => plan.poolSize || 0);
  const isFractional = plans.some((plan) => plan.isFractional);
  const optionsPool = {
    rotation: 270,
    circumference: 180,
    radius: 120,
    maintainAspectRatio: false,
    responsive: true,
    cutout: 68,
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (tooltipItems: any) => {
            const value = tooltipItems.raw;
            const label = `${tooltipItems.label}: ${
              isFractional
                ? parseFloat(Number(value).toFixed(4))
                : Math.floor(Number(value))
            }`;
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Doughnut Chart",
        color: "blue",
        font: {
          size: 24,
        },
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
    },
    elements: {
      arc: {
        spacing: -7,
        borderWidth: 0,
        borderRadius: 25,
      },
    },
  };
  const message = `${getFormattedValue(
    totalOptions || 0,
    currency,
    isFractional
  )} Options`;
  const issuedShares = totalOptions - _sum(plans, "optionsReserved");
  const issued = getFormattedValue(issuedShares || 0, currency, isFractional);
  const availableOptions = _sum(plans, "optionsReserved");
  const available = getFormattedValue(
    availableOptions || 0,
    currency,
    isFractional
  );
  const percent = (100 * issuedShares) / (totalOptions || 1);
  const rows = [
    {
      title: issued,
      subtitle: "Total Options Granted",
    },
    {
      title: available,
      subtitle: "Total Options available",
    },
    {
      title: plans.length,
      subtitle: "Plans",
    },
  ];
  const optionsData = {
    labels: ["Granted", "Available Options"],
    datasets: [
      {
        label: "# of Votes",
        data: [issuedShares, availableOptions],
        backgroundColor: ["rgba(255,168,0,1)", "rgba(255,168,0,.15"],
        borderColor: ["rgba(255,168,0,1)", "rgba(255,168,0,.15"],
      },
    ],
  };
  const navigate = useNavigate();
  return (
    <VStack
      className={`p-6 bg-white rounded-lg max-h-96 cursor-pointer border border-borderColor drop-shadow-box  ${
        isPlaceholderData ? "loading" : ""
      }`}
      onClick={() => {
        if (
          determineUserAccessToResource(
            permission?.aclList || [],
            Resource.OptionsPool,
            Action.Create
          )
        )
          navigate("/options/add-edit-pool");
      }}
    >
      <VStack className="mb-0">
        <p className="font-medium text-gray-dark">{header}</p>
        <p className="text-xs1 font-semi-medium text-gray-light">{message}</p>
      </VStack>
      {!isPlaceholderData && (
        <Box className=" align-center">
          {percent ? (
            <SemiDoughnutGraph options={optionsPool} data={optionsData} />
          ) : (
            <p className="h-10 w-96">.</p>
          )}
        </Box>
      )}
      {rows.map((row) => (
        <HStack
          key={row.subtitle}
          className="justify-between p-2 overflow-auto"
        >
          <HStack>
            <Center
              className="items-center w-6 h-6 p-1"
              style={{ backgroundColor: "#F3F6F9", borderRadius: "50%" }}
            >
              <Icon icon="mdi:octahedron" style={{ color: "gray" }} />
            </Center>
            <VStack className="pl-4">
              <p className="font-medium capitalize text-xs2 text-gray-dark">
                {row.title}
              </p>
              <p className="capitalize text-xs1 font-semi-medium text-gray-light">
                {row.subtitle}
              </p>
            </VStack>
          </HStack>
        </HStack>
      ))}
    </VStack>
  );
}
export default CardPool;
