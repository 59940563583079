import * as _ from "lodash";
import { Employee, EmployeesOverviewDto } from "../types/Employee";
import { EsopPlan, PlanState } from "../types/EsopPlan";
import { Grant, VestingDateType } from "../types/Grant";
import {
  CumulativeOffer,
  EventType,
  LiquidityState,
  MonetaryDetails,
  MonetaryEventState,
  MonetaryOffers,
  MonetaryTransactionType,
  OfferFilter,
  OfferState,
  OptionBuyBackDetails,
  RecurringIntervalUnit,
  RecurringSetting,
} from "../types/OptionBuyback";
import {
  TriggerType,
  VestingEventReq,
  VestingTemplate,
  VestingType,
} from "../types/VestingTemplate";
import {
  VestingMilestoneTableDetails,
  MilestoneStatus,
  ClosureType,
  MilestoneType,
} from "../types/milestone";
import { Valuation, ValuationSource } from "../types/ValuationType";

export const randomGrant = (): Grant => ({
  planid: _.random(100000, 200000).toString(),
  planName: "x".padStart(_.random(20, 50), "x"),
  planType: "x".padStart(_.random(20, 50), "x"),
  optionHolderId: _.random(100000, 200000).toString(),
  grantIdentifier: "x".padStart(_.random(3, 6), "x"),
  optionHolderName: "x".padStart(_.random(15, 25), "x"),
  optionHolderState: "x".padStart(_.random(15, 25), "x"),
  optionsGranted: _.random(100000, 200000),
  grantPrice: _.random(1000, 2000),
  grantDate: "2020-10-06T18:30:00.000+0000",
  optionsVested: _.random(100000, 200000),
  optionsLapsed: _.random(100000, 200000),
  optionsExercisedWithStock: _.random(100, 200000),
  optionsExercisedWithCash: _.random(100, 200000),
  optionsForfeited: _.random(100, 200000),
  employeeId: _.random(100000, 200000).toString(),
  optionsExercised: _.random(100, 10000),
  employeeName: "x".padStart(_.random(15, 25), "x"),
  employeeIdentifier: "x".padStart(_.random(6, 7), "x"),
  isExerciseRequested: false,
  isVestRequested: false,
  customizedGrantLetter: false,
  vestingId: _.random(100000, 200000).toString(),
  vestingTemplateName: "x".padStart(_.random(20, 40), "x"),
  employmentStatus: "x".padStart(_.random(7, 8), "x"),
  vestingDate: "2013-10-06T18:30:00.000+0000",
  vestingDateType: VestingDateType.GRANT_DATE,
  cliffPeriod: 24,
  vestingTriggerType: TriggerType.TIME,
  isFractional: false,
  availableStates: [],
  isStateChangeAllowed: false,
  isWithdrawLapsed: false,
  isPausedVesting: false,
  isExerciseAllowed: false,
  availableToAccelerate: 0,
  actualVestingDay: "SAME_DAY",
  exercises: [],
  vestings: [],
});

export const randomPlan = (): EsopPlan => ({
  esopPlanId: _.random(1000000, 2000000).toString(),
  planName: "x".padStart(_.random(20, 50), "x"),
  planDescription: "x".padStart(_.random(50, 100), "x"),
  poolSize: _.random(1000000, 2000000),
  scope: "x".padStart(_.random(50, 100), "x"),
  issuedShares: _.random(1000000, 2000000),
  planType: "x".padStart(_.random(50, 100), "x"),
  planHoldingPercentage: _.random(100000, 200000),
  esopPlanState: PlanState.FOR_APPROVAL,
  optionsReserved: _.random(0, 0),
  totalShares: _.random(0, 0),
  planStartDate: "2013-09-30T18:30:00.000+0000",
  conversionNumber: 1,
  conversionShares: 1,
  isFractional: false,
  trustId: _.random(1000000, 2000000).toString(),
  sharesManagedByTrust: _.random(0, 0),
  availableState: [],
  isStateChangeAllowed: false,
  unitName: "x".padStart(_.random(50, 100), "x"),
  unitType: "x".padStart(_.random(50, 100), "x"),
});

export const randomEmployee = (): Employee => ({
  id: _.random(100000, 200000).toString(),
  employeeName: "x".padStart(_.random(15, 25), "x"),
  employeeIdentificationString: "x".padStart(_.random(6, 7), "x"),
  employeeDesignation: "x".padStart(_.random(15, 25), "x"),
  employmentStatus: "x".padStart(_.random(8, 10), "x"),
  department: "x".padStart(_.random(15, 25), "x"),
  email: "x".padStart(_.random(25, 35), "x"),
  totalPercentageVested: _.random(100000, 200000),
  lastDay: "2012-03-31T18:30:00.000+0000",
  dateOfJoin: "2012-03-31T18:30:00.000+0000",
  employmentType: "EMPLOYEE",
  isLoginProvided: true,
  lastLogin: "2012-03-31T18:30:00.000+0000",
  noOfGrants: _.random(10, 200),
  isDeactivated: false,
});

export const randomEmployeeOverview = (): EmployeesOverviewDto => ({
  departmentEmployeeCountDtos: [],
  employees: Array(100)
    .fill(0)
    .map((_i) => randomEmployee()),
  yearofJoiningEmployeeCountDtos: [],
});

export const randomVestingTemplate = (): VestingTemplate => ({
  id: _.random(100000, 200000).toString(),
  vestingTemplateName: "x".padStart(_.random(25, 50), "x"),
  vestingType: VestingType.STANDARD,
  cliffPeriod: _.random(12, 12),
  includeTimeVesting: false,
  includeEventVesting: false,
  numberOfGrantsAffected: _.random(50, 200),
  isEditable: false,
  isDefault: false,
  isFractional: false,
  schedules: [
    {
      eventName: "60 % event base ",
      eventTargetDate: "2023-07-03",
      percentage: 60,
      id: "3573c58e-3932-4a18-a6c2-876cc97ceb11",
      vestingScheduleState: "OPEN",
      sequenceNumber: 1,
      vestingDuration: 0,
      vestingInterval: 0,
      vestingTriggerType: TriggerType.EVENT,
      milestoneId: "21e4c4ae-854c-4f16-9339-5c15d0d636ce",
    },
    {
      eventName: "40 % Event Base ",
      eventTargetDate: "2024-01-03",
      percentage: 40,
      id: "8ebf6dfa-1ccd-4a7f-a885-a49a3a863c4f",
      vestingScheduleState: "OPEN",
      sequenceNumber: 2,
      vestingDuration: 0,
      vestingInterval: 0,
      vestingTriggerType: TriggerType.EVENT,
      milestoneId: "d46e93cd-17d6-4aa4-b1d0-1b5ef31eaf64",
    },
  ],
  createdAt: "Fri Jan 01 2016 05:30:00 GMT+0000 (Coordinated Universal Time)",
});

export const randomVestingEvents = (): VestingEventReq => ({
  eventName: "x".padStart(_.random(20, 50), "x"),
  eventTargetDate: "2013-09-30T18:30:00.000+0000",
  vestingScheduleState: "x".padStart(_.random(20, 50), "x"),
  isEditable: false,
  vestingTemplateName: "x".padStart(_.random(20, 50), "x"),
  employeeNames: [],
  grantsINumber: _.random(100000, 200000),
});

export const randomOptionBuybackOverview = (): OptionBuyBackDetails => ({
  id: _.random(200, 200000).toString(),
  companyId: _.random(200000, 200000).toString(),
  eventName: "x".padStart(_.random(10, 10), "x"),
  description: "x".padStart(_.random(2000, 2000), "x"),
  eventPercentage: _.random(48, 48),
  offerFilter: randomOfferFilter(),
  askWillingness: false,
  buyerName: "x".padStart(_.random(4000, 4000), "x"),
  vestingCutOffDate: "2020-10-06T18:30:00.000+0000",
  startDate: "2020-10-06",
  endDate: "2020-10-09",
  liquidityState: LiquidityState.DRAFT,
  createdAt: "2020-10-06T18:30:00.000+0000",
  eventState: MonetaryEventState.DRAFT,
  eventType: EventType.CASH,
  isDeleted: false,
  isOnAutoPilot: false,
  transactionType: MonetaryTransactionType.BUYBACK,
  updatedAt: "2020-10-06T18:30:00.000+0000",
  monetaryDetails: randomMonetaryDetails(),
  monetaryOffers: Array(10)
    .fill(0)
    .map((_i) => randomMonetaryOffers()),

  recurringSetting: randomRecurringSettings(),
});

export const randomOfferFilter = (): OfferFilter => ({
  department: [],
  minimumTenure: _.random(100000, 200000),
  minimumVestedOptions: _.random(100000, 200000),
  minimumWillingness: _.random(100000, 200000),
  minimumWillingnessPercentage: _.random(100000, 200000),
  participationConstraints: [],
});

export const randomMonetaryDetails = (): MonetaryDetails => ({
  fmvPrice: _.random(100000, 200000),
  miscCharges: _.random(100000, 200000),
  price: _.random(100000, 200000),
  sellingPrice: _.random(100000, 200000),
  paymentLink: "x".padStart(_.random(2000, 4000), "x"),
  includeStrikePrice: false,
});

export const randomMonetaryOffers = (): MonetaryOffers => ({
  id: _.random(200000, 200000).toString(),
  companyId: _.random(200000, 200000).toString(),
  eventId: _.random(200000, 200000).toString(),
  grantId: "11361607-1429-4d28-bdcd-fbaacbacc87b",
  amountReceived: 17016,
  createdAt: new Date(),
  updatedAt: new Date(),
  eligibility: 191,
  willingness: 0,
  offerState: OfferState.ACCEPTED,
  employeeId: "9328a51c-ee25-49c2-b796-f4f6a52e4612",
  isDeleted: false,
  conversionRatio: 1,
  conversionMethodology: "ROUND_DOWN",
});

export const randomMilestoneData = (): VestingMilestoneTableDetails => ({
  id: _.random(200000, 200000).toString(),
  milestoneName: "x".padStart(_.random(10, 10), "x"),
  targetDate: new Date("2020-10-06T18:30:00.000+0000"),
  completionDate: new Date("2020-10-06T18:30:00.000+0000"),
  employeesLinked: _.random(100000, 200000),
  employeesCompleted: _.random(100000, 200000),
  employeesPending: _.random(100000, 200000),
  totalNoOfOptions: _.random(100000, 200000),
  totalNoOfOptionsVested: _.random(100000, 200000),
  totalNoOfOptionsLapsed: _.random(10000, 200000),
  milestoneStatus: MilestoneStatus.COMPLETED,
  closureType: ClosureType.Manual,
  milestoneType: MilestoneType.CompanyWide,
  employeeNames: [],
});

export const randomValuationDetails = (): Valuation => ({
  name: "x".padStart(_.random(10, 10), "x"),
  id: _.random(200000, 200000).toString(),
  dateOfValuation: "2020-10-06T18:30:00.000+0000",
  reportDate: "2020-10-06T18:30:00.000+0000",
  unitType: _.random(200000, 200000).toString(),
  pps: _.random(100000, 200000),
  valuationSource: ValuationSource.COMPANY,
  valuationMethodology: _.random(200000, 200000).toString(),
  valuer: _.random(200000, 200000).toString(),
  valuerRgNo: _.random(200000, 200000).toString(),
  valuationReport: _.random(200000, 200000).toString(),
  valuationReportName: _.random(200000, 200000).toString(),
  valuationReportType: _.random(200000, 200000).toString(),
  isReportOverrided: false,
});

export const randomRecurringSettings = (): RecurringSetting => ({
  isRecurring: false,
  recurringInterval: _.random(100000, 200000),
  recurringIntervalUnit: RecurringIntervalUnit.MONTH,
});

export const randomOfferList = (): CumulativeOffer => ({
  cashExercisedOptions: _.random(100000, 200000),
  employeeId: _.random(100000, 200000).toString(),
  employeeName: "x".padStart(_.random(15, 25), "x"),
  granted: _.random(100000, 200000),
  vested: _.random(100000, 200000),
  exercised: _.random(100000, 200000),
  eligibility: _.random(100000, 200000),
  willingness: _.random(100000, 200000),
  state: OfferState.DRAFT,
  totalPriceToPay: _.random(100000, 200000),
  stockExercisedOptions: _.random(100000, 200000),
  forfeitedOptions: _.random(100000, 200000),
  isFractional: false,
});
