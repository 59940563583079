import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SearchAutoComplete from "../../components/shared/SearchAutoComplete";
import { Box, HStack, VStack } from "../../components/utils";
import { useEmployees, useGrants } from "../../queries";
import BCHeader from "../../shared/BCHeader";
import { Employee } from "../../types/Employee";
import ActivityLog from "./ActivityLog";
import Documents from "./Documents";
import EmployeeHeader from "./EmployeeHeader";
import GrantsVSGrade from "./GrantsVSGrade";
import Profile from "./profile";
import { Resignation } from "./Resignation";

function EmployeeDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: _employees, isFetching, isPlaceholderData } = useEmployees();
  const employees: Employee[] = useMemo(
    () => _employees || [],
    [id, isPlaceholderData]
  );
  const { data: _grants } = useGrants();

  const [activeTab, setActiveTab] = useState<string>("Grant Details");
  const [selectedEmp, setSelectedEmp] = useState<Employee>();
  const showTabs = (e: React.MouseEvent<HTMLElement>) => {
    setActiveTab((e.target as any).innerHTML);
    e.stopPropagation();
  };
  function getSelectedValue(employee: any) {
    setSelectedEmp(employee);
  }

  useEffect(() => {
    if (!selectedEmp) return;
    navigate(`/options/employeeDetails/${selectedEmp?.id}`);
    if (activeTab === "Resignation") setActiveTab("Grant Details");
  }, [selectedEmp]);

  useEffect(() => {
    if (isFetching) return;
    const initialFilterValue = employees?.filter((emp) => emp.id === id);
    if (initialFilterValue.length === 0) {
      toast(`You don't have permission to access this Employee with id:${id}`, {
        type: "error",
        autoClose: 3000,
      });
      navigate("/options/allEmployees/");
    }
    setSelectedEmp(initialFilterValue[0]);
  }, [id, isFetching]);

  return (
    <VStack className={`gap-4 ${isPlaceholderData ? "loading" : ""} `}>
      <HStack className="items-center justify-between h-8">
        <BCHeader
          className="items-baseline "
          bcTitle="Stakeholders"
          bcSubTitle={selectedEmp?.employeeName ?? ""}
        />
        {selectedEmp ? (
          <Box className="w-48 h-8 bg-white rounded">
            <SearchAutoComplete
              data={employees}
              getSelectedValue={getSelectedValue}
              selectedOption={selectedEmp}
              field={"employeeName"}
            />
          </Box>
        ) : (
          ""
        )}
      </HStack>
      <EmployeeHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onClick={showTabs}
      />
      {activeTab === "Grant Details" && <GrantsVSGrade />}
      {activeTab === "Profile" && <Profile />}
      {activeTab === "Full Activity Log" && <ActivityLog />}
      {activeTab === "Documents" && <Documents />}
      {activeTab === "Resignation" && (
        <Resignation setActiveTab={setActiveTab} />
      )}
    </VStack>
  );
}

export default EmployeeDetails;
