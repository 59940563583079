import { LinearProgress, LinearProgressProps } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import {
  CellClassParams,
  CellEditorSelectorResult,
  ColDef,
  GridApi,
  ICellEditorParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import _, { set } from "lodash";
import { AgGridReact } from "ag-grid-react";
import { getGrantTemplate } from "../../api/Esop";
import FileInput from "../../components/shared/FileInput";
import { Input, Label } from "../../components/shared/InputField";
import Tooltip from "../../components/shared/Tooltip";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  CellRenderer,
  HStack,
  ValidityCellRenderer,
  VStack,
} from "../../components/utils";
import {
  useAddGrantExcel,
  useEmployees,
  useEsopPlans,
  useVestingTemplates,
} from "../../queries";
import { useCompanyStore } from "../../store";
import {
  GrantResponse,
  GrantValidation,
  ValidationObj,
  VestingDateType,
} from "../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import convertToBase64 from "../../utils/convertToBase64";
import { ValidationResponse } from "../../types/Employee";
import { formatDisplayDate } from "../../utils/date";

function GrantsImport() {
  const { companyData } = useCompanyStore();
  const {
    mutate: addGrantExcel,
    error: addGrantError,
    reset: resetGrantExcel,
  } = useAddGrantExcel();
  const { data: _employeeData } = useEmployees();
  const { data: _allPlans } = useEsopPlans();
  const { data: _vestingTemplates } = useVestingTemplates();
  const [grantValidation, setGrantValidation] = useState<GrantValidation>({
    fileUploaded: false,
    fileValidated: false,
    hasErrors: false,
    data: [],
  });
  const [response, setResponse] = useState<ValidationObj[][]>();
  const [showStatus, setShowStatus] = useState(false);
  const navigate = useNavigate();
  const [hasErrors, setHasErrors] = useState(false);
  const gridApi = useRef<GridApi | any>(null);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [canSelectedBeImported, setCanSelectedBeImported] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };

  function clearForm(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    e.currentTarget.value = "";
    setResponse(undefined);
    setGrantValidation({
      data: [],
      fileUploaded: false,
      fileValidated: false,
      hasErrors: false,
    });
  }
  const proceedToImport = (all = true) => {
    setOperationInProgress(true);
    let data: ValidationResponse[][] = [];
    if (all) {
      gridApi.current?.forEachNode((node: any) => {
        data.push(node.data);
      });
    } else {
      const selectedRows = gridApi.current?.getSelectedRows();
      if (selectedRows.length === 0) {
        toast("No Rows Selected", {
          autoClose: 2000,
          type: "error",
        });
        setOperationInProgress(false);
        return;
      }
      data = selectedRows;
    }
    if (data.length > 0) {
      data.forEach((dataItem) => {
        dataItem.forEach((item) => {
          if (item.name === "Options Granted") {
            item.value = Number(item.value);
          }
        });
      });
      addGrantExcel(
        {
          file: "",
          correctedGrantData: data,
        },
        {
          onSuccess: (data) => {
            toast(data.message, { type: "success" });
            navigate("/options/allPlans");
          },
          onError: (error) => {
            const errorData = error.response.data?.data;
            if (errorData?.message) {
              toast(errorData.message, { type: "error" });
            }
            if (fileRef.current) fileRef.current.value = "";
            setOperationInProgress(false);
          },
        }
      );
    } else setOperationInProgress(false);
  };
  const employees = useMemo(
    () =>
      _employeeData?.map((employee) => employee.employeeIdentificationString),
    [_employeeData]
  );
  const plans = useMemo(
    () => _allPlans?.map((plan) => plan.planName),
    [_allPlans]
  );
  const vestingTemplates = useMemo(
    () => _vestingTemplates?.map((template) => template.vestingTemplateName),
    [_vestingTemplates]
  );
  function handleChange(e: any) {
    setShouldReset(false);
    const file = e.target?.files[0];
    if (!file) return;
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file?.name.endsWith(extension))
    ) {
      setShowStatus(true);
      convertToBase64(file).then((data) => {
        const base64 = {
          file: data as string,
        };
        addGrantExcel(base64, {
          onSuccess: (data) => {
            setShowStatus(false);
            toast(data.message, { type: "success" });
            navigate("/options/allPlans");
          },
          onError: (error) => {
            setShowStatus(false);
            if (fileRef.current) fileRef.current.value = "";
            const errorData = error.response.data?.data;
            if (errorData?.message) {
              toast(errorData.message, { type: "error" });
            }
          },
        });
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
      setShowStatus(true);
      e.target.value = "";
    }
  }
  const downloadTemplate = () => {
    getGrantTemplate().then((uploadTemplate) => {
      const template = uploadTemplate.data.uploadTemplate;
      convertBase64ToBlob(
        template,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ).then((blobObject) => {
        downloadBlobObject(
          blobObject,
          `${companyData?.name} Grant Upload Template.xlsx`
        );
      });
    });
  };
  const checkErrors = (data: ValidationResponse[][], setErrors = true) => {
    const err = data
      .filter((dataItem) =>
        dataItem.find((dataEntity) => dataEntity.name !== "validity")
      )
      .some((dataItem) => dataItem.some((data) => data.valid === false));
    if (setErrors) {
      if (err) {
        setHasErrors(true);
      } else {
        setHasErrors(false);
      }
    }

    return err;
  };
  const [validationData, columnNames] = useMemo(() => {
    let err = false;
    let columnNames: string[] = [];
    if (shouldReset) {
      return [[], []];
    }
    const errorData = addGrantError?.response?.data?.data;
    if (!errorData) return [[], []];
    const validationData = errorData?.validationData?.map((dataItem) => {
      let rowHasErr = false;
      dataItem.forEach((data) => {
        if (["Date Of Grant", "Vesting Date"].includes(data.name) && data.value)
          data.value = new Date(data.value).toDateString();
        if (data.error) {
          err = true;
          rowHasErr = true;
        }
      });
      return [...dataItem];
    });
    if ((validationData || []).length > 0) {
      const keys = _.maxBy(validationData, Object.keys);
      if (keys) {
        columnNames = [
          "validity",
          ...Object.values(keys || {}).map((value) => value?.name),
        ];
      }
      checkErrors(validationData, true);
    }
    return [validationData, columnNames];
  }, [addGrantError?.response?.data?.data, shouldReset]);

  const getCellStyle = (params: CellClassParams<any, any>) => {
    const colId = params.column.getColId();
    if (colId === "validity") {
      if (params.data.find((dataItem: { valid: boolean }) => !dataItem.valid))
        return { color: "red", backgroundColor: "unset" };
      return { color: "black", backgroundColor: "unset" };
    } else {
      return params?.value?.valid
        ? { color: "black", backgroundColor: "unset" }
        : { color: "red", backgroundColor: "unset" };
    }
  };
  const resetData = () => {
    if (fileRef.current) fileRef.current.value = "";
    resetGrantExcel();
  };
  function setValueAndResetCell(params: ValueSetterParams) {
    const dataItem = params?.data;
    if (params.newValue === undefined || params.newValue === null) return false;
    dataItem.forEach((data: any) => {
      if (data.name === params.colDef.field) {
        if (data.value !== params.newValue) {
          data.value = params.newValue;
          data.valid = true;
          data.error = null;
        }
      }
    });
    params.node?.setData(dataItem);
    const data: any[] = [];
    gridApi.current?.forEachNode((node: any) => {
      data.push(node.data);
    });
    checkErrors(data);
    gridApi.current?.refreshCells();
    return true;
  }
  function isRowValid(rowData: ValidationResponse[]) {
    const err = rowData
      ?.filter((dataItem) => dataItem.name !== "validity")
      ?.some((dataItem) => !dataItem.valid);
    return !err;
  }
  const valueGetter = (params: ValueGetterParams<any, any>) => {
    const colId = params.column.getColId();
    const data = params?.data?.find(
      (dataItem: any) => dataItem.name === colId
    ) || { value: undefined, valid: true };
    if (colId === "validity") {
      const isRowDataValid = isRowValid(params?.data);
      const validityValue: ValidationResponse = {
        name: "validity",
        valid: isRowDataValid,
        value: isRowDataValid ? "Valid" : "Invalid",
        error: isRowDataValid ? null : "Validation Error",
      };
      return validityValue;
    }
    if (
      (colId === "Date Of Grant" || colId === "Vesting Date") &&
      data?.value
    ) {
      data.value = formatDisplayDate(data?.value as unknown as string);
    }
    return data;
  };
  const cellEditorSelector: (
    params: ICellEditorParams<any>
  ) => CellEditorSelectorResult | undefined = (
    params: ICellEditorParams<any>
  ) => {
    const field = params.colDef.field;
    switch (field?.toLowerCase()) {
      case "employee id": {
        return {
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            values: employees || [],
          },
        };
      }
      case "plan name": {
        return {
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            values: plans || [],
          },
        };
      }
      case "vesting template": {
        return {
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            values: vestingTemplates || [],
          },
        };
      }
      case "vesting date type": {
        return {
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            values: ["GrantDate", "EmployeeJoiningDate", "CustomDate"],
          },
        };
      }
      case "date of grant":
      case "vesting date": {
        return {
          component: "agDateCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            min: "1900-01-01",
            max: "2100-12-31",
          },
        };
      }
      default: {
        return undefined;
      }
    }
  };
  const valueFormatter = (params: ValueFormatterParams<any, any>) => {
    const item = params.column.getColId();
    if (item === "Date Of Grant" || item === "Vesting Date") {
      if (!params.value.value) {
        return "";
      }
      const formattedDate = formatDisplayDate(
        params.value.value as unknown as string
      );
      return formattedDate;
    }
    return "";
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      checkboxSelection: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () =>
      columnNames?.map((item, index) => {
        const colDef: ColDef = {
          headerName: item,
          checkboxSelection: index === 0,
          floatingFilter: true,
          valueGetter,
          field: item,
          cellRenderer:
            item === "validity" ? ValidityCellRenderer : CellRenderer,
          cellRendererParams: ({ value }: { value: any }) => value,
          cellEditorSelector,
          cellStyle: getCellStyle,
          valueFormatter,
          valueSetter: setValueAndResetCell,
          filterValueGetter: (params) => {
            const data = params.data?.find(
              (dataItem: any) => dataItem.name === item
            );
            if (item === "validity") {
              return isRowValid(params?.data || []) ? "Valid" : "Invalid";
            }
            return data?.value;
          },
          cellEditorParams: (params: any) => {
            const field = params.colDef.field;
            if (
              (field === "Date Of Grant" || field === "Vesting Date") &&
              params.value.value
            ) {
              const data = {
                ...params.value,
                value: new Date(params.value.value),
              };
              return data;
            }
            return params.value;
          },
          headerClass: "text-sm font-normal capitalize",
          cellDataType:
            item === "Date Of Grant" || item === "Vesting Date"
              ? "date"
              : "string",
          editable: true,
          pinned:
            item === "validity" || item === "Employee Id" ? "left" : undefined,
        };
        return colDef;
      }),
    [columnNames]
  );

  return (
    <VStack className="justify-between w-full h-full px-4 bg-white border-2 rounded-md min-h-[600px]">
      <VStack>
        <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
          <h6 className="flex-1">Upload Grants</h6>
          <ButtonPrimary onClick={downloadTemplate}>
            Download Template
          </ButtonPrimary>
        </HStack>
        <VStack className="w-full h-full gap-6 px-2 py-4 bg-white">
          {showStatus && (
            <VStack className="justify-between w-full h-full gap-6 px-2 py-4 my-auto bg-white">
              <LinearProgress className="w-full" variant="indeterminate" />
              <HStack className="justify-between w-full ">
                <p className="text-sm font-normal text-center">
                  Please wait while we validate the data
                </p>
              </HStack>
            </VStack>
          )}
          {!showStatus && (
            <>
              <Box className="flex justify-center w-full px-10 text-lg font-medium">
                <h6 className="flex justify-between">
                  Upload your Grants Excel{" "}
                  <Tooltip
                    text={`Upload your excel sheet containing grant data`}
                    _className="w-[400px]"
                  >
                    <Icon
                      icon="material-symbols:info-outline-rounded"
                      height={24}
                      width={24}
                      className="w-full ml-2 text-xs font-medium rounded cursor-pointer text-slate-dark"
                    />
                  </Tooltip>
                </h6>
              </Box>
              <HStack className="gap-8 pt-8 mx-auto">
                <div className="flex-1">
                  <Label className="text-sm font-normal">Excel File</Label>
                  <input
                    ref={fileRef}
                    // disabled={excelConfig.rawHeaderData !== undefined}
                    className="w-full form-input"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleChange}
                  />
                  <span className="py-2 text-xs italic text-gray-500">
                    You can download the template for uploading grants{" "}
                    <a
                      className="text-blue-500 underline cursor-pointer"
                      onClick={downloadTemplate}
                    >
                      {" "}
                      here
                    </a>
                  </span>
                </div>
              </HStack>
            </>
          )}

          {validationData && validationData.length > 0 && (
            <VStack>
              {" "}
              <div className="py-4 ag-theme-material" style={{ height: 500 }}>
                <AgGridReact
                  key={"GRANTIMNPORTGRID"}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection={"multiple"}
                  rowData={validationData}
                  rowClass={"border-t border-dashed"}
                  suppressRowClickSelection={true}
                  onSelectionChanged={() => {
                    const selectedRows = gridApi.current?.getSelectedRows();
                    if ((selectedRows || []).length === 0) {
                      setCanSelectedBeImported(false);
                      return;
                    }
                    const err = checkErrors(selectedRows, false);
                    if (err) {
                      setCanSelectedBeImported(false);
                    } else {
                      setCanSelectedBeImported(true);
                    }
                  }}
                />
              </div>
              <HStack className="justify-end gap-4 p-3">
                <ButtonPrimary1
                  type="reset"
                  className="text-red-500"
                  onClick={() => {
                    resetData();
                  }}
                >
                  Discard
                </ButtonPrimary1>
                {!operationInProgress && (
                  <ButtonPrimary
                    disabled={hasErrors}
                    onClick={() => proceedToImport()}
                  >
                    Import All
                  </ButtonPrimary>
                )}
                {!operationInProgress && (
                  <ButtonPrimary
                    disabled={!canSelectedBeImported}
                    onClick={() => proceedToImport(false)}
                  >
                    Import Selected
                  </ButtonPrimary>
                )}
                {operationInProgress && (
                  <Icon
                    className="animate-spin text-orange-501"
                    icon="lucide:loader-2"
                    width={36}
                  />
                )}
              </HStack>
            </VStack>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
}

export default GrantsImport;
