import React, { useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import EmployeesTable from "./EmployeesTable";
import { Box, HStack, VStack } from "../../components/utils";
import JoiningYear from "./joiningYear";
import { useEmployees } from "../../queries/employees";
import Department from "./department";
import EmployeeTableFilters from "./employeeTableFilters";
import BCHeader from "../../shared/BCHeader";
import AddEmployee from "../../modals/AddEmployee";
import useIsMobile from "../../utils/detectDevice";
import { Employee } from "../../types/Employee";

function AllEmployees(): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = useIsMobile();
  const { isPlaceholderData, isFetching, data: _data } = useEmployees();

  return (
    <VStack className={`gap-4 ${isPlaceholderData ? "loading" : ""} `}>
      <BCHeader
        className={`items-baseline ${isMobile ? "text-xs" : ""}`}
        bcTitle="Stakeholders"
        buttonName="Add Option Holder"
        onClick={() => setOpen(!open)}
        bcDescription="Detailed listing and profiles of participants featuring individual award histories and current holdings."
      />
      <Dialog open={open} maxWidth="md">
        <div className="w-[900px] h-full">
          <AddEmployee onClose={() => setOpen(!open)} data={[]} />
        </div>
      </Dialog>
      <Box
        className={`grid w-full border border-borderColor drop-shadow-box ${
          isMobile ? "grid-cols-1" : "grid-cols-2"
        } gap-4 rounded-lg `}
      >
        <Department title="Departments" />
        <JoiningYear title="Year of Joining" />
      </Box>
      <HStack className="w-full gap-4">
        <Box className={`${isMobile ? "w-full overflow-x-auto" : "grow"} `}>
          {<EmployeesTable />}
        </Box>
      </HStack>
    </VStack>
  );
}

export default AllEmployees;
