import { useMutation, useQuery } from "react-query";
import {
  completeMilestone,
  createMilestones,
  deleteMilestone,
  getAllMilestones,
  getMilestoneCompleteDetails,
  getMilestoneTableDetails,
  updateMilestone,
} from "../api/milestone";
import { queryClient } from "./client";
import { randomMilestoneData } from "./placeholders";

export function useGetAllMilestones() {
  return useQuery({
    queryKey: "allMilestones",
    queryFn: async () => {
      const response = await getAllMilestones();
      return response.data;
    },
  });
}

export function useGetMilestoneTableDetails() {
  return useQuery({
    queryKey: "tableDetails",
    queryFn: async () => {
      const response = await getMilestoneTableDetails();
      return response.data;
    },
    placeholderData: Array(10)
      .fill(0)
      .map((_i) => randomMilestoneData()),
  });
}
export function useCreateMilestones() {
  return useMutation({
    mutationKey: "createMilestone",
    mutationFn: createMilestones,
    onSuccess: () => {
      queryClient.invalidateQueries("allMilestones");
      queryClient.refetchQueries("allMilestones");
      queryClient.invalidateQueries("tableDetails");
      queryClient.refetchQueries("tableDetails");
    },
    onError: () => {},
  });
}

export function useUpdateMilestones() {
  return useMutation({
    mutationKey: "updateMilestone",
    mutationFn: updateMilestone,
    onSuccess: () => {
      queryClient.invalidateQueries("tableDetails");
      queryClient.refetchQueries("tableDetails");
      queryClient.invalidateQueries("allMilestones");
      queryClient.refetchQueries("allMilestones");
    },
    onError: () => {},
  });
}

export function useGetMilestoneCompleteDetails(id: string) {
  return useQuery({
    queryKey: ["milestoneCompleteDetails", id],
    queryFn: getMilestoneCompleteDetails,
  });
}

export function useDeletetMilestone() {
  return useMutation({
    mutationKey: "deleteMilestone",
    mutationFn: deleteMilestone,
    onSuccess: () => {
      queryClient.invalidateQueries("tableDetails");
      queryClient.refetchQueries("tableDetails");
    },
  });
}

export function useCompleteMilestone() {
  return useMutation({
    mutationKey: "completeMilestone",
    mutationFn: completeMilestone,
    onSuccess: () => {},
    onError: () => {},
  });
}
