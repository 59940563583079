import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
} from "../../components/utils";
import NewGrantPage from "./NewGrantPage";
import GrantInformationPage from "./grantInformation/GrantInformationPage";
import GrantVestingSchedule from "./vestingSchedule/GrantVestingSchedule";
import GrantSummaryPage from "./GrantSummaryPage";

type CompanyDetailsNavigationProps = {
  currentStep: number;
  onStepChange: (e?: number) => void;
  onBackClick: () => void;
};
export default function GrantDetailsNavigation(
  props: Readonly<CompanyDetailsNavigationProps>
) {
  const navigate = useNavigate();
  const [prevData, setPrevData] = useState();

  const { currentStep, onStepChange, onBackClick } = props;
  const isLoading = false; //ToDo
  return (
    <Box className="w-full h-full pb-4 mx-auto align-middle">
      <HStack>
        {currentStep === 1 && (
          <NewGrantPage onStepChange={onStepChange} onBackClick={onBackClick} />
        )}
        {currentStep === 2 && (
          <GrantInformationPage
            onStepChange={onStepChange}
            onBackClick={onBackClick}
          />
        )}
        {currentStep === 3 && (
          <GrantVestingSchedule
            onStepChange={onStepChange}
            onBackClick={onBackClick}
          />
        )}
        {currentStep === 4 && (
          <GrantSummaryPage
            onStepChange={onStepChange}
            onBackClick={onBackClick}
          />
        )}
      </HStack>
    </Box>
  );
}
