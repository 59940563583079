import { useMutation, useQuery } from "react-query";
import {
  addValuation,
  deleteValuation,
  editValuation,
  getValuationDetails,
} from "../api/Valuation";
import { queryClient } from "./client";
import { randomValuationDetails } from "./placeholders";

export function useValuationDetails() {
  return useQuery({
    queryKey: "allValuationDetails",
    queryFn: getValuationDetails,
    placeholderData: Array(10)
      .fill(0)
      .map((_i) => randomValuationDetails()),
  });
}

export function useAddValuation() {
  return useMutation({
    mutationKey: "addValuation",
    mutationFn: addValuation,
    onSuccess: () => {
      queryClient.invalidateQueries("allValuationDetails");
      queryClient.refetchQueries("allValuationDetails");
    },
    onError: () => {},
  });
}

export function useEditValuation() {
  return useMutation({
    mutationKey: "editValuation",
    mutationFn: editValuation,
    onSuccess: () => {
      queryClient.invalidateQueries("allValuationDetails");
      queryClient.refetchQueries("allValuationDetails");
    },
  });
}

export function useDeleteValuation() {
  return useMutation({
    mutationKey: "deleteValuation",
    mutationFn: deleteValuation,
    onSuccess: () => {
      queryClient.invalidateQueries("allValuationDetails");
      queryClient.refetchQueries("allValuationDetails");
    },
  });
}
