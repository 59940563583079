import { Dialog } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import AlertDialog from "../../components/shared/AlertDialog";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  companyClassDropdownValues,
  currencyDropDownValues,
} from "../../constants/DropDownValues";
import { useCreateESOPCompany } from "../../queries/onBoarding";
import BCHeader from "../../shared/BCHeader";

function CreateCompany() {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const { mutate: createEsopCompany, isLoading } = useCreateESOPCompany();
  const discardCompany = () => {
    setDialog({
      open: true,
      message: "Do you want to discard the company?",
    });
  };
  const validationCompanyDetailsSchema = Yup.object().shape({
    name: Yup.string().required("Company Name is required"),
    cin: Yup.string().required("CIN Number is required"),
    address: Yup.string().label("Address is required"),
    dateOfIncorporation: Yup.string().required(
      "Date of Incorporation is required"
    ),
    email: Yup.string()
      .email("Enter Valid Company Email")
      .required("Email is required"),
    companyCurrency: Yup.string().required("Company Currency is required"),
    nic: Yup.string().required("NIC is required"),
    operatingAs: Yup.string().required("Doing Business As is required"),
    typeOfTheCompany: Yup.string().label("Class of the company"),
    stockSymbol: Yup.string().label("Stock Symbol"),
  });
  function getInitialValue(): any {
    return {
      name: "",
      cin: "",
      address: "",
      dateOfIncorporation: "",
      email: "",
      companyCurrency: "",
      nic: "",
      operatingAs: "",
      typeOfTheCompany: "Private",
      stockSymbol: undefined,
    };
  }

  return (
    <div className="w-11/12 min-h-screen m-auto">
      <div className="px-0 text-lg font-medium py-7">
        <h1>Onboarding</h1>
      </div>
      <BCHeader
        className="items-baseline mb-4"
        bcTitle="OnBoarding"
        bcSubTitle="Company Details"
      />
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValue()}
        onSubmit={(values) => {
          createEsopCompany(values, {
            onSuccess: () => {
              setTimeout(() => {
                navigate("/options/esopOverview");
              }, 1000);
            },
            onError: () => {},
          });
        }}
        validationSchema={validationCompanyDetailsSchema}
      >
        {(formik) => (
          <>
            <Form>
              <Dialog open={dialog.open} maxWidth="md">
                <AlertDialog
                  onClose={() => setDialog({ open: false })}
                  open={dialog.open}
                  message={dialog.message}
                  onPrimaryAction={() => {}}
                  onSecondaryAction={() => setDialog({ open: false })}
                />
              </Dialog>
              <VStack className="w-full gap-6 px-10 py-4">
                <VStack className="gap-2">
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Name of the Company
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Enter Company Name"
                        className=""
                        {...formik.getFieldProps("name")}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <Error text={formik.errors.name?.toString()} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        CIN Number
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Eg:38928432"
                        className=""
                        {...formik.getFieldProps("cin")}
                      />
                      {formik.touched.cin && formik.errors.cin && (
                        <Error text={formik.errors.cin?.toString()} />
                      )}
                    </div>
                  </HStack>
                </VStack>
                <HStack className="gap-8">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Company Email ID
                      <span className="text-rose-400">*</span>
                    </Label>
                    <Input
                      type="text"
                      placeholder="Eg: example@domain.com"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Error text={formik.errors.email?.toString()} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Date of Incorporation
                      <span className="text-rose-400">*</span>
                    </Label>
                    <Input
                      type="date"
                      placeholder="Eg:12-12-1212"
                      {...formik.getFieldProps("dateOfIncorporation")}
                    />
                    {formik.touched.dateOfIncorporation &&
                      formik.errors.dateOfIncorporation && (
                        <Error
                          text={formik.errors.dateOfIncorporation?.toString()}
                        />
                      )}
                  </div>
                </HStack>
                <HStack className="gap-8">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Corporate Office Address
                    </Label>
                    <TextArea
                      placeholder="Enter Your Company Address"
                      {...formik.getFieldProps("address")}
                    />
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Company Currency
                      <span className="text-rose-400">*</span>
                    </Label>
                    <Select
                      options={currencyDropDownValues}
                      isPlaceHolderDisabled={true}
                      {...formik.getFieldProps("companyCurrency")}
                    />
                    {formik.touched.companyCurrency &&
                      formik.errors.companyCurrency && (
                        <Error
                          text={formik.errors.companyCurrency?.toString()}
                        />
                      )}
                  </div>
                </HStack>
                <HStack className="gap-8">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      NIC
                      <span className="text-rose-400">*</span>
                    </Label>
                    <Input type="text" {...formik.getFieldProps("nic")} />
                    {formik.touched.nic && formik.errors.nic && (
                      <Error text={formik.errors.nic?.toString()} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Doing Business As
                      <span className="text-rose-400">*</span>
                    </Label>
                    <Input
                      type="text"
                      {...formik.getFieldProps("operatingAs")}
                    />
                    {formik.touched.nic && formik.errors.nic && (
                      <Error text={formik.errors.operatingAs?.toString()} />
                    )}
                  </div>
                </HStack>
                <HStack className="gap-8">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Class (Type Of The Company)
                      <span className="text-rose-400">*</span>
                    </Label>
                    <Select
                      options={companyClassDropdownValues}
                      valueGetter={(value) => value.value}
                      textGetter={(value) => value.label}
                      isPlaceHolderDisabled={true}
                      {...formik.getFieldProps("typeOfTheCompany")}
                    />
                    {formik.touched.typeOfTheCompany &&
                      formik.errors.typeOfTheCompany && (
                        <Error
                          text={formik.errors.typeOfTheCompany?.toString()}
                        />
                      )}
                  </div>
                  <div className="flex-1">
                    {formik.values.typeOfTheCompany === "PUBLIC" && (
                      <div className="flex-1">
                        <Label className="text-sm font-normal">
                          Stock Symbol
                        </Label>
                        <Input
                          type="text"
                          {...formik.getFieldProps("stockSymbol")}
                        />
                        {formik.touched.stockSymbol &&
                          formik.errors.stockSymbol && (
                            <Error
                              text={formik.errors.stockSymbol?.toString()}
                            />
                          )}
                      </div>
                    )}
                  </div>
                </HStack>
              </VStack>
              <HStack className="justify-end m-8">
                <ButtonPrimary1
                  type="reset"
                  className="mr-8 text-red-500"
                  onClick={() => {
                    navigate("options/esopOverview");
                  }}
                >
                  Go to Dashboard
                </ButtonPrimary1>
                <ButtonPrimary1
                  type="reset"
                  className="mr-8 text-red-500"
                  onClick={discardCompany}
                >
                  Discard
                </ButtonPrimary1>
                <ButtonPrimary type="submit" loading={isLoading}>
                  Continue
                </ButtonPrimary>
              </HStack>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}

export default CreateCompany;
