import React, { useMemo } from "react";
import { Icon } from "@iconify/react";
import { useParams } from "react-router";
import { Box, HStack, VStack } from "../../components/utils";

type Step = {
  stepNo: number;
  title: string;
  description: string;
  children?: Step[];
};
type StepProps = {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
};
export default function GrantDetailsStepper(props: StepProps) {
  const currentStep = useMemo(() => props.currentStep, [props.currentStep]);
  const steps = useMemo(() => {
    const returnData: Step[] = [
      {
        stepNo: 1,
        title: "New Grant",
        description: "Lorem ipsum",
      },
      {
        stepNo: 2,
        title: "Grant Information",
        description: "Lorem ipsum",
      },
      {
        stepNo: 3,
        title: "Vesting Schedule",
        description: "Lorem ipsum",
      },
      {
        stepNo: 4,
        title: "Summary",
        description: "Lorem ipsum",
      },
    ];
    return returnData;
  }, []);
  return (
    <Box className="h-full">
      {steps.map((step) => (
        <StepContainer
          key={step.stepNo}
          step={step}
          completed={
            parseInt(`${currentStep}`, 10) > parseInt(`${step.stepNo}`, 10)
          }
          current={
            parseInt(`${currentStep}`, 10) === parseInt(`${step.stepNo}`, 10)
          }
          currentStep={currentStep}
          setCurrentStep={props.setCurrentStep}
        />
      ))}
    </Box>
  );
}

const StepContainer = (props: {
  step: Step;
  current?: boolean;
  completed?: boolean;
  nextStepsAvailable?: boolean;
  currentStep?: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  _className?: string;
}) => {
  const { step, current, completed, currentStep, setCurrentStep, _className } =
    props;
  const { mode } = useParams();
  function handleStepClick(
    step: Step,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    if (current || mode !== "edit") {
      return;
    }
    event.stopPropagation();
    setCurrentStep(step.stepNo);
  }
  return (
    <HStack
      className={`w-full cursor-pointer pt-7 ${
        !_className ? "pl-6" : _className
      }`}
    >
      <VStack
        className={`justify-between w-1/5 ${
          mode !== "edit" ? "cursor-not-allowed" : ""
        }`}
        onClick={(_) => handleStepClick(step, _)}
      >
        <div
          className={`rounded-md shadow-md  ${
            current
              ? " text-white bg-orange-501  "
              : "bg-opacity-50 text-orange-501"
          }  w-10 h-10 inline-flex justify-center items-center font-medium`}
        >
          {completed ? (
            <Icon icon="akar-icons:check" className="text-orange-501" />
          ) : (
            step.stepNo
          )}
        </div>
      </VStack>
      <VStack
        className={`w-4/5 justify-evenly ${
          mode !== "edit" ? "cursor-not-allowed" : ""
        }`}
        onClick={(_) => handleStepClick(step, _)}
      >
        <div
          className={`text-sm2 font-medium text-black-501 ${
            current && "text-orange-501"
          } `}
        >
          {step.title}
        </div>
        <div
          className={`text-xs0 text-gray-401 font-medium ${
            mode !== "edit" ? "cursor-not-allowed" : ""
          }`}
          onClick={(_) => handleStepClick(step, _)}
        >
          {step.description}
        </div>
        {step.children && (
          <div className="">
            {step.children.map((child) => (
              <StepContainer
                key={child.stepNo}
                step={child}
                current={currentStep === child.stepNo}
                completed={(currentStep || 0) > child.stepNo}
                setCurrentStep={setCurrentStep}
                _className="pl-0"
              />
            ))}
          </div>
        )}
      </VStack>
    </HStack>
  );
};
