/* eslint-disable etc/no-commented-out-code */
import { Navigate, useRoutes } from "react-router-dom";
import OptionsLayout from "./layouts/dashboard";
import FaqComponent from "./pages/FAQ/Faq";
import NotFound from "./pages/Page404";
import SomeOtherPage from "./pages/SomeOtherPage";
import ValuationOverview from "./pages/Valuation/ValuationOverview";
import AllEmployees from "./pages/allEmployees/AllEmployees";
import EmployeeImport from "./pages/allEmployees/EmployeeImport";
import AllPlans from "./pages/allPlans";
import CreateViewOrEditPlan from "./pages/allPlans/CreateViewOrEditPlan";
import SinglePlanView from "./pages/allPlans/SinglePlanView";
import CreateCompany from "./pages/company/createCompany";
import { CompanyProfile } from "./pages/companyProfile/CompanyProfile";
import DocumentManager from "./pages/document/DocumentManager";
import EmployeeDetails from "./pages/employeeDetails/EmployeeDetails";
import Modelling from "./pages/esopModeling";
import CompensationModelling from "./pages/esopModeling/compModelling";
import CompanyDetails from "./pages/esopModeling/companyDetails";
import HiringPlan from "./pages/esopModeling/hiringPlan";
import AddOrEditPoolSize from "./pages/esopOverview/AddOrEditPoolSize";
import EsopOverview from "./pages/esopOverview/EsopOverview";
import GrantsImport from "./pages/esopOverview/GrantsImport";
import GrantDetails from "./pages/esopOverview/HistoricOnboarding";
import LoginPage from "./pages/login/loginPage";
import { Milestones } from "./pages/milestones";
import { CompleteMilestone } from "./pages/milestones/CompleteMilestone";
import BuybackIndex from "./pages/optionBuyback";
import CompleteBuyback from "./pages/optionBuyback/CompleteBuyback";
import CreateExerciseEvent from "./pages/optionBuyback/CreateExerciseEvent";
import CreateExerciseFlow from "./pages/optionBuyback/CreateExerciseFlow";
import CreateLiquidationFlow from "./pages/optionBuyback/CreateLiquidationFlow";
import CreateOptionBuyback from "./pages/optionBuyback/CreateOptionBuyback";
import LiquidationView from "./pages/optionBuyback/LiquidationView";
import StartBuyback from "./pages/optionBuyback/StartBuyback";
import ViewOptionBuybackDetails from "./pages/optionBuyback/ViewOptionBuybackDetails";
import { OptionSplit } from "./pages/optionSplit/OptionSplit";
import { Playground } from "./pages/playground";
import SiteSettings from "./pages/settings/SiteSettings";
import UserProfilePage from "./pages/userProfile/UserProfilePage";
import UserRoles from "./pages/usersRoles/UserRoles";
import { VestingTemplates } from "./pages/vestingSchedules";
import { useAuthStore } from "./store";
import { usePermissionStore } from "./store/permissionStore";
import { determineUserAccessToResource } from "./utils/auth";
import { Action, Resource } from "./utils/interfaces/Companies";
import UploadGrantLetters from "./pages/esopOverview/UploadGrantLetters";
import SyncDetails from "./pages/syncs/SyncDetails";
import IndividualSyncDetails from "./pages/syncs/IndividualSyncDetails";
import Reports from "./pages/reports/Reports";
import AddGrantDetails from "./pages/grants";

export default function Router() {
  const { isAuthenticated } = useAuthStore();
  const { permission } = usePermissionStore();

  return useRoutes([
    {
      path: "/login",
      element: !isAuthenticated ? <LoginPage /> : <Navigate to="/" replace />,
    },
    {
      path: "/company/createCompany",
      element: isAuthenticated ? (
        determineUserAccessToResource(
          permission?.aclList || [],
          Resource.Company,
          Action.Create
        ) ? (
          <CreateCompany />
        ) : (
          <NotFound />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "/options",
      element: isAuthenticated ? (
        determineUserAccessToResource(
          permission?.aclList || [],
          Resource.All,
          Action.Read
        ) ? (
          <OptionsLayout />
        ) : (
          <NotFound />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "esopOverview", element: <EsopOverview /> },
        {
          path: "plan/:mode?/:id?",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateViewOrEditPlan />
          ) : (
            <NotFound />
          ),
        },
        { path: "completeMilestone/:id", element: <CompleteMilestone /> },
        {
          path: "add-edit-pool",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <AddOrEditPoolSize />
          ) : (
            <NotFound />
          ),
        },

        {
          path: "grant/:mode?/:id?",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.Grant,
            Action.Read
          ) ? (
            <AddGrantDetails />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "allEmployees",
          element: <AllEmployees />,
          children: [
            {
              path: "employeeDetails/:id",
              element: determineUserAccessToResource(
                permission?.aclList || [],
                Resource.All,
                Action.Read
              ) ? (
                <EmployeeDetails />
              ) : (
                <NotFound />
              ),
            },
          ],
        },
        {
          path: "employeeDetails/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <EmployeeDetails />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "onboarding/historic",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.All
          ) ? (
            <GrantDetails />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "onboarding/grants",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.Grant,
            Action.Create
          ) ? (
            <GrantsImport />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "onboarding/uploadGrantLetters",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.Grant,
            Action.Create
          ) ? (
            <UploadGrantLetters />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "onboarding/employees",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.Employee,
            Action.Create
          ) ? (
            <EmployeeImport />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "allPlans",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <AllPlans />
          ) : (
            <NotFound />
          ),
          children: [
            {
              path: "planView/:id",
              element: determineUserAccessToResource(
                permission?.aclList || [],
                Resource.All,
                Action.Read
              ) ? (
                <SinglePlanView />
              ) : (
                <NotFound />
              ),
            },
          ],
        },
        {
          path: "planView/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <SinglePlanView />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "vestingSchedules",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <VestingTemplates />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "milestones",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <Milestones />
          ) : (
            <NotFound />
          ),
          children: [
            { path: "completeMilestone/:id", element: <CompleteMilestone /> },
          ],
        },
        {
          path: "reports",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <Reports />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "esopTransactions",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.All
          ) ? (
            <BuybackIndex />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "esopTransactions/exerciseRequests",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.All
          ) ? (
            <BuybackIndex />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "create-option-buyback",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateOptionBuyback />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "create-exercise-flow",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateExerciseFlow />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "create-exercise-event",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateExerciseEvent />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "create-exercise-liquidation-flow",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateExerciseFlow />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "create-liquidation-flow",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateLiquidationFlow />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "liquidation-details/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <LiquidationView />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "edit-options-buyback/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateOptionBuyback />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "edit-exercise-flow/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateExerciseFlow />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "exercise-event/:mode/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateExerciseEvent />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "edit-liquidation-flow/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CreateLiquidationFlow />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "start-buy-back/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <StartBuyback />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "complete-buy-back/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CompleteBuyback />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "view-option-buy-back/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <ViewOptionBuybackDetails />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "playground",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.All
          ) ? (
            <Playground />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "optionSplit",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Edit
          ) ? (
            <OptionSplit />
          ) : (
            <SomeOtherPage />
          ),
        },
        {
          path: "usersRoles",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.All
          ) ? (
            <UserRoles />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "someOtherPage",
          element: isAllowed("someOtherPage") ? (
            <SomeOtherPage />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "valuationReports",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <ValuationOverview />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "documents",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.All
          ) ? (
            <DocumentManager />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "employeeSyncs",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <SyncDetails />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "syncDetails/:id",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <IndividualSyncDetails />
          ) : (
            <NotFound />
          ),
        },
      ],
    },
    {
      path: "/modelling",
      element: isAuthenticated ? (
        <OptionsLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "home", element: <Modelling /> },
        { path: "companyDetails", element: <CompanyDetails /> },
        { path: "hiringPlan", element: <HiringPlan /> },
        { path: "compensationModelling", element: <CompensationModelling /> },
      ],
    },
    {
      path: "/settings",
      element: isAuthenticated ? (
        <OptionsLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "companyProfile",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <CompanyProfile />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "siteSettings",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <SiteSettings />
          ) : (
            <NotFound />
          ),
        },
        {
          path: "faq",
          element: determineUserAccessToResource(
            permission?.aclList || [],
            Resource.All,
            Action.Read
          ) ? (
            <FaqComponent />
          ) : (
            <NotFound />
          ),
        },
      ],
    },
    {
      path: "/auth",
      element: isAuthenticated ? (
        determineUserAccessToResource(
          permission?.aclList || [],
          Resource.All,
          Action.Read
        ) ? (
          <OptionsLayout />
        ) : (
          <NotFound />
        )
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [{ path: "usersRoles", element: <UserRoles /> }],
    },
    {
      path: "/allPlans",
      element: <AllPlans />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    { path: "/", element: <Navigate to="/options/esopOverview" replace /> },
    {
      path: "/settings",
      element: <Navigate to="/settings/siteSettings" replace />,
    },
    {
      path: "/user",
      element: isAuthenticated ? (
        <OptionsLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [{ path: "profile", element: <UserProfilePage /> }],
    },
  ]);
}
function isAllowed(route: string) {
  // check from user object permissions
  // ideally a call
  return true;
}
