import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Input } from "./InputField";
import { sort } from "../../utils/arrays";
import { HStack } from "../utils";

function SearchAutoComplete<T>({
  getSelectedValue,
  data,
  selectedOption,
  field,
  selectOption,
}: {
  getSelectedValue: Function;
  data: T[];
  selectedOption: T;
  field: keyof T;
  selectOption?: Function;
}) {
  const sortedData = sort(data || [], field);
  const [searchText, setSearchText] = useState(
    selectedOption && (selectedOption[field] as any)
  );
  const [searched, setSearched] = useState<T[]>();

  const [open, setOpen] = useState(false);

  function searchOptions(text: string) {
    const match = sortedData.filter((d) => {
      const regex = new RegExp(
        `${text.replace(/[.*+?^=!:${}()|\\]/g, "\\$&")}`,
        "gi"
      );
      return (d[field] as any).match(regex);
    });
    setSearched(match);
  }

  const contextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!searchText) setSearchText(selectedOption[field]);
      if (!(open && contextRef.current?.contains(e.target))) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [open, searchText]);

  useEffect(() => {
    setSearchText(selectedOption && (selectedOption[field] as any));
  }, [selectedOption, field]);

  return (
    <div ref={contextRef} className="absolute z-10 bg-white cursor-pointer">
      <HStack className="items-center w-48 h-8 text-xs rounded shadow-sm bg-inherit text-slate-dark">
        <div className="h-8">
          <input
            type="text"
            className="w-full min-h-[20px] px-2 text-xs h-8 font-medium outline-none cursor-pointer bg-inherit form-input p-1 pr-8 bg-white w-44 text-ellipsis"
            value={searchText}
            placeholder="Select"
            onChange={(e) => {
              if (!e.target.value) setSearched(sortedData);
              setSearchText(e.target.value);
              searchOptions(e.target.value);
            }}
            onFocus={() => {
              setOpen(true);
              setSearched(sortedData);
              searchOptions(searchText);
            }}
          />
        </div>
        {open ? (
          <Icon
            icon="material-symbols:keyboard-arrow-up-rounded"
            width="24"
            className="mr-2 "
            onClick={() => setOpen(!open)}
          />
        ) : (
          <Icon
            icon="material-symbols:keyboard-arrow-down-rounded"
            width="24"
            className="mr-2 "
            onClick={() => setOpen(!open)}
          />
        )}
      </HStack>
      <div className="overflow-auto border divide-y divide-gray-100 rounded shadow-sm max-h-60">
        {open &&
          searched?.map((o, index) => {
            const option = o as unknown as T;
            return (
              <div
                className="flex gap-2 p-1 p-2 pr-8 text-xs bg-white divide-y rounded w-44 text-ellipsis"
                key={index}
                onClick={() => {
                  setSearchText(option[field]);
                  getSelectedValue(option);
                  setOpen(!open);
                  if (selectOption) {
                    selectOption(option);
                  }
                }}
              >
                {option[field] as unknown as string | number}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default SearchAutoComplete;
