import { useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { HStack, VStack } from "../../components/utils";
import { useBuybackOffers } from "../../queries/optionBuyback";
import {
  MonetaryEventState,
  MonetaryTransactionType,
  OfferState,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";

function BuybackEmployeeDetails({
  buybackDetail,
}: {
  buybackDetail: OptionBuyBackDetails;
}) {
  const currency = getCurrencyType();
  const { id } = useParams();
  const _id = id ?? "";

  const { data: _buybackStateData } = useBuybackOffers(_id);
  const buybackStateData = _buybackStateData || [];

  const totalTax = _.sumBy(
    buybackStateData,
    (offer) => offer?.perquisiteTaxAmount || 0
  );

  const totalCashOut = _.sumBy(
    buybackStateData,
    (offer) => offer?.totalCashout || 0
  );

  const totalStrikePrice = _.sumBy(
    buybackStateData,
    (offer) => offer?.totalStrikePrice || 0
  );

  const totalNetPayout = _.sumBy(
    buybackStateData,
    (offer) => offer?.netPayout || 0
  );

  useEffect(() => {
    let willingNess = 0;
    if (!buybackDetail && buybackStateData?.length <= 0) return;
    buybackStateData?.forEach((item) => {
      willingNess += item.willingness;
    });
  }, [buybackDetail, buybackStateData]);

  let totalAcceptedOffersByEmployee = 0;
  let totalUnacceptedOffersByEmployee = 0;
  buybackStateData.forEach((item) => {
    if (
      item.state === OfferState.ACCEPTED ||
      item.state === OfferState.COMPLETED ||
      item.state === OfferState.PENDING_EXERCISED
    ) {
      totalAcceptedOffersByEmployee += 1;
    } else if (
      item.state === OfferState.EXPIRED ||
      item.state === OfferState.REJECTED
    ) {
      totalUnacceptedOffersByEmployee += 1;
    }
  });

  let totalWillingNess = 0;
  function getTotalWillingNess() {
    buybackStateData?.forEach((item) => {
      if (
        item.state === OfferState.ACCEPTED ||
        item.state === OfferState.COMPLETED ||
        item.state === OfferState.PENDING_EXERCISED
      )
        totalWillingNess += item.willingness;
    });
    return totalWillingNess;
  }
  function getTotalEligibility() {
    let totalEligibility = 0;
    buybackStateData.forEach((item) => {
      totalEligibility += item.eligibility;
    });
    return totalEligibility;
  }
  function calculateDaysToBuybackAccept() {
    const daysDiff = Math.ceil(
      (new Date(buybackDetail?.endDate).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
    );
    if (daysDiff > 0) {
      return daysDiff;
    } else return 0;
  }
  const empDetail = [
    {
      name: `Days left to Accept ${
        buybackDetail?.transactionType === MonetaryTransactionType.BUYBACK
          ? "Buy Back"
          : "Liquidity program"
      }`,
      total: calculateDaysToBuybackAccept().toString().concat(" days"),
      display: true,
    },
    {
      name: "Eligible Employees",
      total: _buybackStateData?.length,
      display: true,
    },
    {
      name: "Accepted/Rejected",
      total: `${
        buybackDetail?.eventState !== MonetaryEventState.DRAFT
          ? `${totalAcceptedOffersByEmployee} / ${totalUnacceptedOffersByEmployee}`
          : "Event yet to start"
      }`,
      display: true,
    },
    {
      name: ` Total ${
        buybackDetail?.transactionType === MonetaryTransactionType.BUYBACK
          ? "Cash out"
          : "Amount"
      } `,
      total: `${formatCurrency(totalCashOut, currency)}`,
      display: true,
    },
    {
      name: "amount payable by Employees",
      total: `${formatCurrency(totalStrikePrice, currency)}`,
      display: true,
    },
    {
      name: "Total Tax",
      total: `${formatCurrency(totalTax, currency)}`,
      display: true,
    },
    {
      name: "Net payout",
      total: `${formatCurrency(totalNetPayout, currency)}`,
      display: true,
    },
    {
      name: "Accepted Options / Eligible Options",
      total: `${getTotalWillingNess().toLocaleString(
        currency
      )} / ${getTotalEligibility().toLocaleString(currency)}`,
      display: true,
    },
  ];

  return (
    <VStack className={`bg-[#fefefe]`}>
      <div className="flex px-6 py-4 font-semibold text-md text-gray-dark">
        <h6>Employee Details</h6>
      </div>
      <div className="flex items-center p-4 rounded drop-shadow-md">
        <CardContainer cards={empDetail} />
      </div>
    </VStack>
  );
}

export default BuybackEmployeeDetails;

export const CardContainer = (props: any) => (
  <div className="flex items-center py-2 cards-container">
    {props.cards
      .filter((item: any) => item.display === true)
      .map((card: any, index: number) => (
        <HStack
          key={index}
          className="items-center w-full gap-4 mx-2 border rounded cursor-pointer card whitespace-nowrap drop-shadow-sm"
        >
          <Card title={card.name} content={card.total} />
        </HStack>
      ))}
  </div>
);

export const Card = (props: any) => (
  <VStack className="items-center gap-4 px-12 py-8 font-medium text-center text-black text-xs3">
    <p className="capitalize">{props.title}</p>
    <p className="font-normal whitespace-nowrap">{props.content}</p>
  </VStack>
);
