import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSize,
  Center,
  HStack,
  VStack,
} from "../../components/utils";
import { AddOrEditGrant } from "../esopOverview/AddOrEditGrant";

import AlertDialog from "../../components/shared/AlertDialog";
import {
  Employee,
  EmployementStatus,
  RevokeAccessRequest,
} from "../../types/Employee";

import { jwtAdminForEmployee } from "../../api/Esop";
import StatusIndicator from "../../components/shared/StatusIndicator";
import { InitiateResignation } from "../../modals/InitiateResignation";
import {
  useDeleteEmployee,
  useEmployeePageDetails,
  useEmployeeTransactionDetails,
  useGenerateEmployeeCredentials,
  useGetResignationDetails,
  useRevokeOrEnableAccess,
  useTransferEmployeeAccess,
} from "../../queries/employees";
import { usePauseVesting } from "../../queries/vest";
import { useError } from "../../store/errorStore";
import { usePermissionStore } from "../../store/permissionStore";
import { useSignaturePadDialog } from "../../store/useDialogStore";
import {
  EmployeePageDetails,
  PauseVestingDto,
} from "../../types/VestingTemplate";
import { determineUserAccessToResource } from "../../utils/auth";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { getFormattedValue } from "../../utils/string";
import { Input } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { formatDisplayDate } from "../../utils/date";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#66D497" : "#F5F5F5",
  },
}));

type EmployeeHeaderTypes = {
  activeTab: string;
  setActiveTab: Function;
} & React.HTMLAttributes<HTMLElement>;

const EmployeeHeader = (props: EmployeeHeaderTypes) => {
  const currency = getCurrencyType();
  const { id } = useParams();
  const _id = id ?? "";
  const navigate = useNavigate();

  const errorMessage = useError();
  const {
    data: _employeePageDetail,
    refetch,
    isLoading,
  } = useEmployeePageDetails(_id);
  const { data: transactionDetails } = useEmployeeTransactionDetails(_id);
  const employeePageDetail = _employeePageDetail as EmployeePageDetails;
  useEffect(() => {
    refetch();
  }, []);
  const { permission } = usePermissionStore();
  const isUserAdmin = useMemo(
    () =>
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    [permission]
  );

  const [dialog, setDialog] = useState<{
    open: boolean;
    user?: Employee | EmployeePageDetails;
    mode?: "Edit" | "Add" | "Clone" | "Delete";
    message?: string;
    primaryAction?: () => void;
  }>({ open: false, user: undefined, mode: "Add" });
  const [employeeAccessDialog, setEmployeeAccessDialog] = useState<{
    open: boolean;
    personalEmail?: string;
    regenerateCredentials?: boolean;
  }>({ open: false, personalEmail: "" });
  const [employeeDetailsDialog, setEmployeeDetailsDialog] = useState<{
    open: boolean;
    url: string;
  }>({ open: false, url: "" });
  const { activeTab, setActiveTab, ...rest } = props;
  const { mutate: deleteEmp, isLoading: deletingEmployee } =
    useDeleteEmployee();
  const { mutate: revokeOrEnableAccess, isLoading: revokingOrEnablingAccess } =
    useRevokeOrEnableAccess();
  const { mutate: pauseVesting, isLoading: resumeVesting } = usePauseVesting();
  const { mutate: transferAccess, isLoading: transferringAccess } =
    useTransferEmployeeAccess();
  const [open, setOpen] = useState<boolean>(false);
  const [initiationOpen, setInitiationOpen] = useState<boolean>(false);
  const employeeGrantedOption = employeePageDetail?.grantedOptions ?? 0;
  const employeeVestedOption = employeePageDetail?.vestedOptions ?? 0;
  const employeeExercisedOption = employeePageDetail?.exercisedOptions ?? 0;
  const employeeSharesReceived = employeePageDetail?.sharesReceived ?? 0;

  const employeeSoldShares = _.sumBy(
    transactionDetails,
    (t) => t.noOfSoldShares
  );
  const employeeBalancedShares = employeeSharesReceived - employeeSoldShares;
  const vestedFactor = employeeVestedOption / employeeGrantedOption;
  const tabs = ["Grant Details", "Profile"];

  const { data: resignationDetails } = useGetResignationDetails(_id);

  if (
    resignationDetails?.initiationDetails?.resignationDate &&
    !employeePageDetail?.userHasLimitedAccessForEmployee
  )
    tabs.push("Resignation");

  const moreActions = [
    {
      name: employeePageDetail?.isPausedVesting
        ? "Resume Vesting"
        : "Pause Vesting",
      disabled:
        employeePageDetail?.userHasLimitedAccessForEmployee ||
        employeePageDetail?.status === EmployementStatus.RESIGNED ||
        resignationDetails?.initiationDetails.grantTableDetails.length === 0 ||
        !isUserAdmin ||
        employeePageDetail?.grantedOptions <= 0,
    },
    {
      name: "Initiate Resignation",
      disabled:
        employeePageDetail?.status !== EmployementStatus.EMPLOYED ||
        !isUserAdmin ||
        employeePageDetail?.userHasLimitedAccessForEmployee ||
        !!employeePageDetail?.resignationDate,
    },
    {
      name: `${
        employeePageDetail?.deactivate !== true
          ? "Revoke Access"
          : "Enable Access"
      }`,
      disabled:
        !isUserAdmin ||
        employeePageDetail?.userHasLimitedAccessForEmployee ||
        !employeePageDetail?.isLoginProvided,
    },
    {
      name: "Transfer access to Personal Email",
      disabled:
        !isUserAdmin ||
        employeePageDetail?.userHasLimitedAccessForEmployee ||
        employeePageDetail?.status !== EmployementStatus.RESIGNED ||
        !determineUserAccessToResource(
          permission?.aclList || [],
          Resource.Employee,
          DefaultAction.Edit
        ),
    },
    {
      name: "Preview Employee View",
      disabled: !isUserAdmin,
    },
    {
      name: "Delete Employee",
      disabled:
        employeePageDetail?.grantedOptions !== 0 ||
        (employeePageDetail?.isLoginProvided !== null
          ? employeePageDetail.isLoginProvided
          : false) ||
        !isUserAdmin ||
        employeePageDetail?.userHasLimitedAccessForEmployee ||
        !determineUserAccessToResource(
          permission?.aclList || [],
          Resource.Employee,
          DefaultAction.Delete
        ),
    },
  ];

  const {
    mutate: generateEmployeeCredentials,
    isLoading: generateCredentialLoading,
  } = useGenerateEmployeeCredentials();
  function genCred() {
    generateEmployeeCredentials(
      {
        employeeIds: [_id],
        regenerateExisting: true,
        sendCredentialsGenerationEmail: true,
      },
      {
        onSuccess: () => {
          toast("Credentials Generated successfully!", {
            type: "success",
            autoClose: 2000,
          });
          setDialog({ open: false });
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  function deleteEmployee() {
    if (employeePageDetail?.grantedOptions === 0) {
      deleteEmp(_id, {
        onSuccess: () => {
          toast("Employee deleted successfully!", {
            type: "success",
            autoClose: 2000,
          });
          setDialog({ open: false });
          navigate("/options/allEmployees");
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          setDialog({ open: false });
        },
      });
    } else {
      toast("Employee can not be deleted as employee has grants", {
        type: "error",
        autoClose: 5000,
      });
      setDialog({ open: false });
    }
  }
  function revokeAccess() {
    const revokeAccessDto: RevokeAccessRequest = {
      empId: _id,
      isDeactived: true,
    };
    revokeOrEnableAccess(revokeAccessDto, {
      onSuccess: () => {
        toast("Employee is successfully disabled", {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  function pauseVestingFunction() {
    const pauseVestingDto: PauseVestingDto = {
      employeeId: employeePageDetail.id,
      isPausedVesting: !employeePageDetail.isPausedVesting,
    };
    pauseVesting(pauseVestingDto, {
      onSuccess: (res) => {
        toast(res.data, {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response);
        toast(err.response, { type: "error", autoClose: 2000 });
      },
    });
  }

  function enableAccess() {
    const revokeAccessDto: RevokeAccessRequest = {
      empId: _id,
      isDeactived: false,
    };
    revokeOrEnableAccess(revokeAccessDto, {
      onSuccess: () => {
        toast("Employee is successfully enabled", {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  function redirectToEmployeePortal() {
    jwtAdminForEmployee(_id).then(
      (data) => {
        const jwtAccessToken = data.data;
        const employeeUrl = `${process.env.REACT_APP_EMP_PORTAL}/login?token=${jwtAccessToken}&adminEmployeeView=true`;
        setEmployeeDetailsDialog({ open: true, url: employeeUrl });
      },
      (err) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      }
    );
  }
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  function handleAction(user: EmployeePageDetails, action: Action) {
    if (action.disabled) return;
    switch (action.name) {
      case "Initiate Resignation": {
        setInitiationOpen(!initiationOpen);
        break;
      }
      case "Pause Vesting": {
        setDialog({
          open: true,
          user,
          message: "Are you sure you want to Pause Vesting ?",
          primaryAction: pauseVestingFunction,
        });
        break;
      }
      case "Resume Vesting": {
        setDialog({
          open: true,
          user,
          message:
            "Are you sure you want to Resume Vesting ? All paused options will be vested up until today!",
          primaryAction: pauseVestingFunction,
        });
        break;
      }
      case "Revoke Access": {
        setDialog({
          open: true,
          user,
          message: "Are you sure you want to Revoke Access ?",
          primaryAction: revokeAccess,
        });
        break;
      }
      case "Enable Access": {
        setDialog({
          open: true,
          user,
          message: "Are you sure you want to Enable Access ?",
          primaryAction: enableAccess,
        });
        break;
      }
      case "Send Summary in Email": {
        setDialog({
          open: true,
          user,
          message: "Are you sure you want to Send Summary in Email ? ",
        });
        break;
      }
      case "Delete Employee": {
        setDialog({
          open: true,
          user,
          message: "Are you sure you want to delete the Employee ?",
          mode: "Delete",
          primaryAction: deleteEmployee,
        });
        break;
      }
      case "Transfer access to Personal Email": {
        setEmployeeAccessDialog({
          open: true,
          personalEmail: employeePageDetail?.personalEmail,
          regenerateCredentials: false,
        });
        break;
      }
      case "Preview Employee View": {
        redirectToEmployeePortal();
        break;
      }
      default: {
        break;
      }
    }
  }
  const [show, setShow] = useState(false);
  const { isMobile } = useIsMobile();
  return (
    <Box
      className={`${isLoading ? "loading" : ""} p-6 pb-0 bg-white rounded-lg"`}
    >
      <Box
        className={`flex ${isMobile ? "flex-col" : "flex-row"}  w-full gap-2`}
      >
        <VStack className="items-center">
          <Box className="items-center rounded bg-slate-501">
            <Center className="relative w-40 h-40 bg-gray-100 ">
              {!employeePageDetail?.imageURL ? (
                <>
                  <Center className="flex w-40 h-40 rounded-full ">
                    <p className="text-base font-bold ">
                      {employeePageDetail?.employeeName.charAt(0)}
                    </p>
                  </Center>
                  <StatusIndicator
                    className="absolute"
                    status={employeePageDetail?.status ?? ""}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <Center className="w-fit h-fit">
                    <img
                      className="max-h-36 aspect-square"
                      src={employeePageDetail?.imageURL}
                    />
                  </Center>
                  <StatusIndicator
                    className="absolute"
                    status={employeePageDetail?.status}
                  />
                </>
              )}
            </Center>
          </Box>
          <HStack className="flex items-center justify-center mt-2">
            <StatusIndicator
              className=""
              status={employeePageDetail?.status ?? ""}
            />
            <Box className="text-sm">
              <p className="capitalize">
                {employeePageDetail?.status.toLowerCase()}
              </p>
            </Box>
          </HStack>
        </VStack>
        <Box className="w-full">
          <HStack className="flex-wrap justify-between gap-4">
            <VStack className="justify-between gap-4">
              <VStack>
                <Box className="flex flex-row items-center space-x-2">
                  <Box className="flex flex-row items-center min-w-min max-w-[450px] font-semibold text-lg text-gray-600">
                    <p>{employeePageDetail?.employeeName}</p>
                    <p className="ml-2">
                      {employeePageDetail?.employeeIdentification}
                    </p>
                  </Box>
                  {employeePageDetail?.isLoginProvided === true ? (
                    <Box className="p-1 rounded text-xxs1 bg-green-301 text-green-401">
                      <p> Credentials Generated </p>
                    </Box>
                  ) : (
                    <Box className="p-1 text-red-400 bg-red-100 rounded text-xxs1">
                      <p>Credentials not generated </p>
                    </Box>
                  )}
                </Box>
                <Box className="flex flex-row space-x-4">
                  <Box className="flex flex-row items-center space-x-1 text-xs text-gray-201">
                    <Box className=" text-xxs1">
                      <Icon icon="carbon:user-filled" />
                    </Box>
                    <Box className="mr-1 text-xxs1 justify-bottom">
                      <p>{employeePageDetail?.employmentType}</p>
                    </Box>
                  </Box>
                  <Box className="flex flex-row items-center space-x-1 text-xs text-gray-201">
                    <Box className="text-xxs1">
                      <Icon icon="icon-park-solid:handbag" />
                    </Box>
                    <Box className="mr-1 text-xxs1">
                      <p>{employeePageDetail?.designation}</p>
                    </Box>
                  </Box>
                  <Box className="flex flex-row items-center space-x-1 text-xs text-gray-201">
                    <Box className=" text-xxs1">
                      <Icon icon="ci:location" />
                    </Box>
                    <Box className="mr-1 text-xxs1">
                      <p>{employeePageDetail?.residentStatus}</p>
                    </Box>
                  </Box>
                </Box>
              </VStack>
              <VStack className="gap-4">
                <Box className="flex flex-row space-x-4">
                  <VStack className="p-2 text-xs border border-dashed rounded xl:w-36 lg:w-28 md:w-20 sm:w-16">
                    <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                      <p>
                        {getFormattedValue(
                          employeeGrantedOption,
                          currency,
                          employeePageDetail?.isFractional
                        )}
                      </p>
                    </Box>
                    <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                      Granted
                    </Box>
                  </VStack>
                  <VStack className="p-2 text-xs border border-dashed rounded xl:w-36 lg:w-28 md:w-20 sm:w-16">
                    <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                      <p>
                        {getFormattedValue(
                          employeeVestedOption,
                          currency,
                          employeePageDetail?.isFractional
                        )}
                      </p>
                    </Box>
                    <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                      Vested
                    </Box>
                  </VStack>
                  <VStack className="p-2 text-xs border border-dashed rounded xl:w-36 lg:w-28 md:w-20 sm:w-16">
                    <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                      <p>
                        {getFormattedValue(
                          employeeExercisedOption,
                          currency,
                          employeePageDetail?.isFractional
                        )}
                      </p>
                    </Box>
                    <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                      Exercised
                    </Box>
                  </VStack>
                </Box>
                <Box className="flex flex-row space-x-4">
                  <VStack className="p-2 text-xs border border-dashed rounded xl:w-36 lg:w-28 md:w-20 sm:w-16">
                    <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                      <p>
                        {getFormattedValue(
                          employeeSharesReceived,
                          currency,
                          employeePageDetail?.isFractional
                        )}
                      </p>
                    </Box>
                    <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                      Shares received
                    </Box>
                  </VStack>
                  <VStack className="p-2 text-xs border border-dashed rounded xl:w-36 lg:w-28 md:w-20 sm:w-16">
                    <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                      <p>
                        {getFormattedValue(
                          employeeSoldShares,
                          currency,
                          employeePageDetail?.isFractional
                        )}
                      </p>
                    </Box>
                    <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                      Shares sold
                    </Box>
                  </VStack>
                  <VStack className="p-2 text-xs border border-dashed rounded xl:w-36 lg:w-28 md:w-20 sm:w-16">
                    <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                      <p>
                        {getFormattedValue(
                          employeeBalancedShares,
                          currency,
                          employeePageDetail?.isFractional
                        )}
                      </p>
                    </Box>
                    <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                      Balance shares
                    </Box>
                  </VStack>
                </Box>
                {employeePageDetail?.showPPSToEmployees ? (
                  <Box className="flex flex-col ">
                    <Box className="flex flex-row space-x-4">
                      <VStack className="p-2 text-xs border border-dashed rounded xl:min-w-36 lg:min-w-28 md:min-w-20 sm:min-w-16">
                        <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                          <p>
                            {formatCurrency(
                              employeePageDetail?.ppsforEmployee || 0,
                              currency
                            )}
                          </p>
                        </Box>

                        <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                          Price Per Share
                        </Box>
                      </VStack>
                      <VStack className="p-2 text-xs border border-dashed rounded xl:min-w-36 lg:min-w-28 md:min-w-20 sm:min-w-16">
                        <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                          <p>
                            {formatCurrency(
                              employeePageDetail?.totalOptionValue,
                              currency
                            )}
                          </p>
                        </Box>
                        <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                          Total Option Value
                        </Box>
                      </VStack>
                      <VStack className="p-2 text-xs border border-dashed rounded xl:min-w-36 lg:min-w-28 md:min-w-20 sm:min-w-16">
                        <Box className="p-1 pb-0 font-semibold text-black-dark text-xs3">
                          <p>
                            {formatCurrency(
                              employeePageDetail?.totalOptionCost,
                              currency
                            )}
                          </p>
                        </Box>
                        <Box className="p-1 mr-2 text-xxs1 text-gray-201">
                          Total Option Cost
                        </Box>
                      </VStack>
                    </Box>
                  </Box>
                ) : (
                  <p></p>
                )}
              </VStack>
            </VStack>
            <VStack className="justify-between gap-4">
              {/* vested factor */}
              <Box className="flex flex-row space-x-4 ">
                <VStack>
                  <HStack className="justify-end gap-4">
                    <ButtonPrimary
                      buttonSize={ButtonSize.SMALL}
                      className={
                        !determineUserAccessToResource(
                          permission?.aclList || [],
                          Resource.Employee,
                          DefaultAction.Create
                        )
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }
                      disabled={
                        !determineUserAccessToResource(
                          permission?.aclList || [],
                          Resource.Employee,
                          DefaultAction.Create
                        )
                      }
                      onClick={() => {
                        setDialog({
                          open: true,
                          message: `Are you sure you want to generate credentials?`,
                          primaryAction: genCred,
                        });
                      }}
                    >
                      {employeePageDetail?.isLoginProvided !== true
                        ? "Generate Credentials"
                        : "Re-Generate Credentials"}
                    </ButtonPrimary>
                    <Box className="items-center px-2 py-3 bg-gray-300 rounded-sm">
                      <CTADropdown
                        actions={moreActions}
                        onAction={(action) =>
                          handleAction(employeePageDetail, action)
                        }
                      />
                    </Box>
                  </HStack>
                  {employeePageDetail?.isLoginProvided &&
                    employeePageDetail?.lastCredentialsGeneratedDate && (
                      <HStack className="py-2 text-xs text-gray-201">
                        <Box>
                          <div>Generated On : </div>
                        </Box>
                        <Box>
                          {formatDisplayDate(
                            employeePageDetail?.lastCredentialsGeneratedDate
                          )}
                        </Box>
                      </HStack>
                    )}
                </VStack>

                <Dialog open={open} maxWidth="md">
                  <AddOrEditGrant
                    onClose={() => setOpen(!open)}
                    grant={undefined}
                    mode={dialog.mode}
                  />
                </Dialog>

                <Dialog open={initiationOpen} maxWidth="md">
                  <InitiateResignation
                    onClose={() => setInitiationOpen(!initiationOpen)}
                    onPrimaryAction={() => {
                      setActiveTab("Resignation");
                      setInitiationOpen(!initiationOpen);
                    }}
                  />
                </Dialog>
                <Dialog
                  open={dialog.open}
                  onClose={() => setDialog({ open: false })}
                  maxWidth="md"
                >
                  <AlertDialog
                    onClose={() => setDialog({ open: false })}
                    open={dialog.open}
                    message={dialog.message}
                    onPrimaryAction={dialog.primaryAction}
                    onSecondaryAction={() => setDialog({ open: false })}
                    loading={
                      generateCredentialLoading ||
                      deletingEmployee ||
                      resumeVesting ||
                      revokingOrEnablingAccess
                    }
                  />
                </Dialog>
                <Dialog
                  open={employeeAccessDialog.open}
                  onClose={() => setEmployeeAccessDialog({ open: false })}
                  maxWidth="md"
                >
                  <Box className="overflow-x-auto overflow-y-auto p-4 w-full min-w-[600px]">
                    <Box className="sticky py-4 text-lg font-medium border-b">
                      <h6 className="flex justify-between">
                        Transfer employee access
                        <span
                          onClick={() =>
                            setEmployeeAccessDialog({ open: false })
                          }
                          className="cursor-pointer"
                        >
                          X
                        </span>
                      </h6>
                    </Box>
                    <HStack className="justify-between w-full gap-4 mt-4 align-middle">
                      <VStack className="my-auto align-middle">
                        Personal Email :
                      </VStack>
                      <VStack className="w-3/4">
                        <Input
                          value={employeeAccessDialog.personalEmail}
                          type="email"
                          onChange={(e) => {
                            setEmployeeAccessDialog({
                              open: employeeAccessDialog.open,
                              personalEmail: e.target.value,
                            });
                          }}
                        />
                      </VStack>
                    </HStack>
                    <HStack className="justify-end w-full gap-6 my-4">
                      <VStack className="">Regenerate Credentials ?</VStack>
                      <VStack>
                        <SwitchButton
                          onClick={() => {
                            setEmployeeAccessDialog({
                              open: employeeAccessDialog.open,
                              regenerateCredentials:
                                !employeeAccessDialog.regenerateCredentials,
                              personalEmail: employeeAccessDialog.personalEmail,
                            });
                          }}
                          value={
                            employeeAccessDialog.regenerateCredentials || false
                          }
                          label=""
                        />
                      </VStack>
                    </HStack>
                    <p className="mt-16 italic text-xxs">{`Note: This action will transfer employee's access to his personal email.`}</p>
                    <HStack className="justify-end w-full gap-4 mt-4">
                      <ButtonPrimary1
                        onClick={() => {
                          setEmployeeAccessDialog({ open: false });
                        }}
                      >
                        Cancel
                      </ButtonPrimary1>
                      <ButtonPrimary
                        buttonSize={ButtonSize.SMALL}
                        loading={transferringAccess || false}
                        disabled={
                          employeeAccessDialog.personalEmail?.length === 0
                        }
                        onClick={() => {
                          transferAccess(
                            {
                              employeeId: employeePageDetail.id,
                              personalEmail:
                                employeeAccessDialog?.personalEmail,
                              regenerateCredentials:
                                employeeAccessDialog.regenerateCredentials,
                            },
                            {
                              onSuccess: () => {
                                toast("Account access has been transferred", {
                                  autoClose: 2000,
                                  type: "success",
                                });
                                setEmployeeAccessDialog({ open: false });
                              },
                              onError: (e) => {
                                toast("Failed to transfer account", {
                                  autoClose: 2000,
                                  type: "error",
                                });
                              },
                            }
                          );
                        }}
                      >
                        Confirm
                      </ButtonPrimary>
                    </HStack>
                  </Box>
                </Dialog>
                <Dialog
                  className="overflow-x-auto overflow-y-auto"
                  open={employeeDetailsDialog.open}
                  maxWidth="xl"
                >
                  <Box className="overflow-x-auto overflow-y-auto">
                    <Box className="sticky px-10 text-lg font-medium border-b py-7">
                      <h6 className="flex justify-between">
                        {"Employee Details "}
                        <span
                          onClick={() =>
                            setEmployeeDetailsDialog({ open: false, url: "" })
                          }
                          className="cursor-pointer"
                        >
                          X
                        </span>
                      </h6>
                    </Box>
                    <iframe
                      className="min-h-[1080px] min-w-[1440px]"
                      src={employeeDetailsDialog.url}
                    ></iframe>
                  </Box>
                </Dialog>
              </Box>
              {(employeeGrantedOption || 0) > 0 ? (
                <Box>
                  <VStack className="p-2 min-w-min">
                    <Box className="flex flex-row justify-between ">
                      <p className="text-stone-300 text-xs4 ">Vested factor</p>
                      <p className="text-black text-xs4">
                        {(vestedFactor * 100).toFixed()}
                        {" %"}
                      </p>
                    </Box>
                    <BorderLinearProgress
                      className=""
                      variant="determinate"
                      value={vestedFactor * 100}
                    />
                  </VStack>
                </Box>
              ) : (
                ""
              )}
            </VStack>
          </HStack>
        </Box>
      </Box>
      <HStack className="gap-8 mb-0 flex-center">
        {tabs.map((item, i) => (
          <Box
            key={i}
            className={`text-xs3 font-semibold cursor-pointer py-4 ${
              item === activeTab
                ? "border-b-2 border-solid border-red-500 text-red-500"
                : "text-gray-201 "
            }`}
            {...rest}
          >
            {item}
          </Box>
        ))}
      </HStack>
    </Box>
  );
};

export default EmployeeHeader;
