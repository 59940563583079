import { Dialog } from "@mui/material";
import { useState } from "react";
import { ButtonPrimary, HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { usePermissionStore } from "../../store/permissionStore";
import { Valuation } from "../../types/ValuationType";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import AddOrEditValuation from "./AddOrEditValuation";
import ValuationTable from "./ValuationTable";
import { useValuationDetails } from "../../queries/Valuation";

function ValuationOverview() {
  const { data: _valuationData, isPlaceholderData } = useValuationDetails();

  return (
    <VStack className={`gap-4  ${isPlaceholderData ? "loading" : ""} `}>
      <div className="flex items-baseline justify-between">
        <BCHeader
          className="items-baseline "
          bcTitle="Valuation Reports"
          bcDescription="Dive into valuation reports detailing the company's equity value over time. Valuation data from these reports will be used to show current price per share, growth and for compensation reporting"
        />
      </div>

      <HStack className="flex flex-row">
        <ValuationTable />
      </HStack>
    </VStack>
  );
}

export default ValuationOverview;
