import { useMemo } from "react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  Box,
  Center,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { useVestingEvents } from "../../queries";
import { useAuthorizationStore } from "../../store";
import { useEditEventDetailsDialog } from "../../store/useDialogStore";
import { VestingEventReq } from "../../types/VestingTemplate";
import { determineUserAccessToResource } from "../../utils/auth";
import { formatDate, formatDisplayDate } from "../../utils/date";
import GrantStatusLabel from "../esopOverview/StatusLabel";
import ImpactedEmployees from "./ImpactedEmployees";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { usePermissionStore } from "../../store/permissionStore";
import { useGetMilestoneTableDetails } from "../../queries/milestone";
import { MilestoneStatus } from "../../types/milestone";

function VestingEvents() {
  const { data: _allMilestoneTableDetails, isFetched } =
    useGetMilestoneTableDetails();
  const allMilestoneTableDetails = useMemo(
    () => _allMilestoneTableDetails || [],
    [_allMilestoneTableDetails, isFetched]
  );

  return (
    <VStack className="p-6 bg-white border rounded-lg max-h-96 border-borderColor drop-shadow-box ">
      <Box className="mb-4 font-medium text-gray-dark">Vesting Milestones</Box>
      <HStack className="overflow-auto">
        <table className="w-full table-space">
          <thead>
            <tr className="text-gray-400 border-b border-dashed font-mediuum text-xs1">
              <td className="px-2 uppercase">Milestone</td>
              <td className="px-2 uppercase">Target Date</td>
              <td className="px-2 uppercase">Employees Impacted</td>
              <td className="px-2 uppercase"></td>
            </tr>
          </thead>
          <tbody className={isFetched ? "" : "loading"}>
            {allMilestoneTableDetails.length > 0 &&
              allMilestoneTableDetails.map((item, index) => (
                <tr
                  key={index}
                  className="font-medium border-b border-dashed text-xs1"
                >
                  <td className="px-2 py-2">
                    <VStack>
                      <p>{item.milestoneName}</p>
                      <p className="w-20 ">
                        <GrantStatusLabel
                          state={item.milestoneStatus}
                        ></GrantStatusLabel>
                      </p>
                    </VStack>
                  </td>
                  <td className="px-2 py-2">
                    <p>{formatDisplayDate(item.targetDate)}</p>
                  </td>
                  <td>
                    <div className="relative flex flex-row py-2 -space-x-2 ">
                      {Array.isArray(item.employeeNames) &&
                        item.employeeNames
                          ?.slice(0, 3)
                          ?.map((employee) => (
                            <ImpactedEmployees
                              key={employee}
                              name={employee}
                              impactedEmployees={item.employeeNames}
                            />
                          ))}

                      <div
                        className={`relative w-8 h-8 text-center text-white ${
                          item.employeeNames.length > 3
                            ? "bg-black ring-2 ring-white border-2 border-white border-solid hover:ring-blue-800"
                            : ""
                        } rounded-full `}
                        style={{
                          backgroundColor:
                            item.employeeNames.length > 3
                              ? "#FF5733"
                              : "transparent",
                        }}
                      >
                        <Center className="py-1.5">
                          <p>+{item.employeeNames.length - 3}</p>
                        </Center>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </HStack>
      {allMilestoneTableDetails.length === 0 && (
        <Center className="h-48">
          <p className="p-4 font-medium text-teal-600 rounded">
            No Vesting Milestones !!{" "}
          </p>
        </Center>
      )}
    </VStack>
  );
}

export default VestingEvents;
