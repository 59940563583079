import { EmployementStatus, EmployementType } from "./Employee";

export interface VestingTemplate {
  id: string;
  vestingTemplateName: string;
  vestingType: VestingType;
  cliffPeriod: number;
  numberOfGrantsAffected: number;
  isEditable: boolean;
  isDefault: boolean;
  schedules: Schedule[];
  includeTimeVesting: boolean;
  includeEventVesting: boolean;
  isFractional: boolean;
  createdAt?: string;
}

export enum VestingType {
  STANDARD = "STANDARD",
  CUSTOM = "CUSTOM",
  MANUAL = "MANUAL",
}

export enum TriggerType {
  BOTH = "BOTH",
  TIME = "TIME",
  EVENT = "EVENT",
}

export interface AddVestingTemplateReq {
  id: string;
  vestingTemplateName: string;
  vestingType: VestingType;
  cliffPeriod: number;
  timeScheduleDtos: Schedule[];
  eventScheduleDtos: Schedule[];
  percentage?: number;
  includeTimeVesting: boolean;
  includeEventVesting: boolean;
  isDefault: boolean;
}
export interface VestingEventReq {
  eventName: string;
  eventTargetDate: string;
  vestingScheduleState: string;
  isEditable: boolean;
  vestingTemplateName: string;
  employeeNames: string[];
  grantsINumber: number;
}

export interface VestingTemplateDetail {
  id: string;
  vestingTemplateName: any;
  isAlreadyInUse: boolean;
  vestingType: VestingType;
  vestingTriggerType: TriggerType;
  cliffPeriod: number;
  vestingInterval: any;
  vestingPeriod: any;
  isDefault: boolean;
  timeScheduleDtos: Schedule[];
  eventScheduleDtos: Schedule[];
}

export interface AddVestingTemplateRes {
  id: string;
  cliff: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  isDeleted: boolean;
  name: string;
  type: string;
}

export interface Schedule {
  id: string;
  sequenceNumber: number;
  percentage: number;
  vestingDuration: number;
  vestingInterval: number;
  eventName: string;
  eventTargetDate: string;
  eventCompletionDate?: string;
  vestingTriggerType: TriggerType;
  vestingScheduleState: string;
  milestoneId?: string;
  newlyAdded?: boolean;
}

export interface EmployeeVestingDetail {
  date: string;
  vestedOptions: number;
  accumulatedGrantedOptionsForEmployee: number;
  accumulatedVestedOptionsForEmployee: number;
  accumulatedExercisedOptionsForEmployee: number;
  grantedOptions: number;
  exercisedOptions: number;
  exercisedOptionsWithCash: number;
  isFractional: boolean;
  optionHolderState?: string;
  optionHolderId?: string;
  identifier?: string;
  stockExercised?: number;
}

export interface EmployeePageDetails {
  id: string;
  employeeName: string;
  imageURL: string;
  grantedOptions: number;
  vestedOptions: number;
  exercisedOptions: number;
  sharesReceived: number;
  status: EmployementStatus;
  residentStatus: string;
  department: string;
  emailAddress: string;
  nationality: string;
  designation: string;
  contactNumber: string;
  isLoginProvided: boolean;
  showPPSToEmployees: boolean;
  ppsforEmployee: number;
  dateOfJoin: string;
  manager: string;
  pan: string;
  address: string;
  grade: string;
  employeeIdentification: string;
  employerName: string;
  employmentType: EmployementType;
  lastDay: string;
  resignationDate?: string;
  notes: string;
  deactivate: boolean;
  isPausedVesting: boolean;
  totalOptionValue: number;
  totalOptionCost: number;
  isFractional: boolean;
  userHasLimitedAccessForEmployee: boolean;
  personalEmail?: string;
  dematAccountId?: string;
  lastCredentialsGeneratedDate: string;
}

export interface EmployeeStockTransactionDetails {
  id: string;
  noOfSoldStock: number;
  unrealisedStock?: number;
  pricePerShare: number;
  amountReceived: number;
  transactionType: string;
  employeeId: string;
  transactionId?: string;
  createdAt: string;
  updatedAt: string;
}

export interface EmployeeStockTransaction {
  amountReceived: number;
  employeeId: string;
  noOfSoldShares: number;
}
export interface Integration {
  secretARN: string;
  token: string;
  tenentname: string;
  status: string;
}

export interface PauseVestingDto {
  employeeId?: string;
  isPausedVesting: boolean;
  esopPlanId?: string;
  optionHolderId?: string;
}
