import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartData,
  TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export function BarChart({
  data,
  isFractional,
}: {
  data: ChartData<"bar", number[], unknown>;
  isFractional: boolean;
}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItems: TooltipItem<"bar">) => {
            const label = `${tooltipItems.dataset.label || ""}: ${
              isFractional
                ? (tooltipItems.raw as string)
                : tooltipItems.formattedValue
            }`;
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        title: { display: true, text: "Date" },
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        title: { display: true, text: "Grants" },
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  };
  return <Bar options={options} data={data} />;
}
