import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import {
  GrantedBy,
  useGrantDetailsStore,
} from "../../store/useGrantDetailsStore";
import SearchDropDown from "../../components/shared/SearchDropdown";
import { useEmployees, useEsopPlans, useGrants } from "../../queries";
import { EmployementStatus } from "../../types/Employee";
import { useCompanyStore } from "../../store";
import canUserBeAssignedToThisPlan from "../../utils/grantRule";
import { Select } from "../../components/shared/Select";

interface NewGrantPageProps {
  onStepChange: (e?: number) => void;
  onBackClick: () => void;
}

const NewGrantPage = (props: NewGrantPageProps) => {
  const { companyData } = useCompanyStore();
  const data = useGrantDetailsStore();
  const { mode, id } = useParams();
  const navigate = useNavigate();
  const { data: _employees } = useEmployees();
  const employees = _.sortBy(_employees || [], "employeeName");
  const employedEmployees = employees.filter(
    (employee) => employee.employmentStatus === EmployementStatus.EMPLOYED
  );
  const { data: grants } = useGrants();
  const { data: _plans, isFetching: plansFetching } = useEsopPlans();
  const plans = _plans || [];
  const plansToShow = useMemo(() => {
    const selectedEmployee = employees.find(
      (employee) => employee.id === data?.employee?.id
    );
    if (selectedEmployee)
      return (
        plans.filter((plan) =>
          canUserBeAssignedToThisPlan(
            plan.planType ?? "",
            selectedEmployee.employmentType?.toString() ?? "",
            companyData?.currency ?? "INR - ₹"
          )
        ) || []
      );
    return plans || [];
  }, [data?.employee, plansFetching]);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (!data.grantedBy) data.setGrantedBy(GrantedBy.OPTIONS);
  }, [data]);

  const checkIfGrantIdExists = (value: string, mode = "add", id = "") => {
    if (grants) {
      const filteredGrants =
        mode === "edit"
          ? grants.filter((g) => g.optionHolderId !== id)
          : grants;
      return filteredGrants.some((grant) => grant.grantIdentifier === value);
    } else return false;
  };

  const validationSchema = Yup.object().shape({
    employee: Yup.object().required("Name of Holder is required"),
    plan: Yup.object().required("Plan Name is required"),
    dateOfGrant: Yup.date()
      .required("Grant Date is required")
      .when("employee", (employee, schema) =>
        schema.test(
          "is-after-joining-and-plan-start",
          "Grant Date must be after the employee's joining date and the plan start date.",
          // eslint-disable-next-line func-names
          function (value) {
            const { plan } = this.parent as { plan?: { planStartDate?: Date } };
            const employeeJoiningDate = employee?.dateOfJoin;

            if (!value || !employeeJoiningDate || !plan?.planStartDate)
              return true;

            const grantDate = new Date(value).setHours(0, 0, 0, 0);
            const joiningDate = new Date(employeeJoiningDate).setHours(
              0,
              0,
              0,
              0
            );
            const planStartDate = new Date(plan.planStartDate).setHours(
              0,
              0,
              0,
              0
            );

            return grantDate >= joiningDate && grantDate >= planStartDate;
          } as (this: Yup.TestContext, value: Date) => boolean
        )
      ),
    grantPrice: Yup.number()
      .required("Grant Price is required")
      .when("plan.planType", {
        is: "RSU",
        then: Yup.number().min(0, "Grant Price cannot be less than 0"),
        otherwise: Yup.number().min(
          0.0001,
          "Grant Price must be greater than 0"
        ),
      }),
    grantId: Yup.string().test(
      "unique-grant-id",
      "This Grant ID already exists.",
      (value) => {
        if (!value) return true;
        const exists = checkIfGrantIdExists(value, mode, id);
        return !exists;
      }
    ),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  function handleSubmit() {
    formik
      .validateForm({
        employee: data.employee,
        grantId: data.grantId,
        plan: data.plan,
        dateOfGrant: data.dateOfGrant,
        grantPrice: data.grantPrice,
      })
      .then((validationErrors) => {
        if (_.isEmpty(validationErrors)) {
          props.onStepChange();
        } else {
          setErrors(validationErrors);
        }
      });
  }

  return (
    <VStack className="w-full">
      {/* Title and Description */}
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          New Grant
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro
          blanditiis doloribus, aut itaque quibusdam aliquam at laudantium
          perspiciatis voluptas omnis reprehenderit molestiae.
        </HStack>
      </VStack>

      {/* Form Fields */}
      <VStack className="gap-4 px-6 pt-4">
        {/* Grant By Options */}
        <VStack>
          <HStack className="items-center justify-between gap-8 px-4 py-4 pr-40 text-center">
            <HStack className="items-center gap-2 text-center">
              <input
                type="radio"
                className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                checked={data.grantedBy === GrantedBy.OPTIONS}
                onChange={() => data.setGrantedBy(GrantedBy.OPTIONS)}
              />
              <Label className="pt-2 text-sm font-normal">
                Grant By Options
              </Label>
            </HStack>
            <HStack className="items-center gap-2">
              <input
                type="radio"
                className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                checked={data.grantedBy === GrantedBy.AMOUNT}
                onChange={() => data.setGrantedBy(GrantedBy.AMOUNT)}
              />
              <Label className="pt-2 text-sm font-normal">
                Grant By Amount
              </Label>
            </HStack>
            <HStack className="items-center gap-2">
              <input
                type="radio"
                className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                checked={data.grantedBy === GrantedBy.MODEL}
                onChange={() => data.setGrantedBy(GrantedBy.MODEL)}
              />
              <Label className="pt-2 text-sm font-normal">Auto Grant</Label>
            </HStack>
          </HStack>
        </VStack>

        {/* Name of Holder */}
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Name of Holder</Label>
            <Select
              placeholder="--Select--"
              options={employedEmployees}
              disabled={mode === "edit"}
              value={data.employee?.id || ""}
              textGetter={(e) =>
                `${e.employeeName}(${e.employeeIdentificationString})`
              }
              valueGetter={(e) => e.id}
              onChange={(e) => {
                const employee = employedEmployees.find(
                  (emp) => emp.id === e.target.value
                );
                if (employee) data.setEmployee(employee);
              }}
              className=""
            />
            {errors.employee && <Error text={errors.employee} />}
          </div>

          {/* Grant ID */}
          <div className="flex-1">
            <Label className="text-sm font-normal">Grant ID (Optional)</Label>
            <Input
              type="text"
              onChange={(e) => data.setGrantId(e.target.value)}
              value={data.grantId}
            />
            {errors.grantId && <Error text={errors.grantId} />}
          </div>
        </HStack>

        {/* Plan Name and Conversion Ratio */}
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Name</Label>
            <Select
              onChange={(e) => {
                const plan = plansToShow.find(
                  (p) => p.esopPlanId === e.target.value
                );
                if (!plan) return;
                data.setPlan(plan);
                const conversionRatio = parseFloat(
                  (1 / (plan?.conversionShares || 1)).toFixed(4)
                );
                data.setConversionRatio(
                  `${conversionRatio} ${
                    conversionRatio > 1 ? "Options" : "Option"
                  } : 1 Share`
                );
              }}
              placeholder="--Select--"
              options={plansToShow}
              value={data.plan?.esopPlanId || ""}
              className=""
              textGetter={(e) => e.planName}
              valueGetter={(e) => e.esopPlanId}
            />
            {errors.plan && <Error text={errors.plan} />}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Conversion Ratio </Label>
            <Input
              className="cursor-not-allowed"
              value={data.conversionRatio || "1 Option : 1 Share"}
              disabled={true}
            />
          </div>
        </HStack>

        {/* Grant Date and Grant Price */}
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Grant Date</Label>
            <Input
              type="date"
              onChange={(e) => data.setDateOfGrant(e.target.value)}
              value={data.dateOfGrant}
            />
            {errors.dateOfGrant && <Error text={errors.dateOfGrant} />}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Grant Price</Label>
            <Input
              type="number"
              onChange={(e) => data.setGrantPrice(parseFloat(e.target.value))}
              value={data.grantPrice}
            />
            {errors.grantPrice && <Error text={errors.grantPrice} />}
          </div>
        </HStack>

        {/* Navigation Buttons */}
        <HStack className="justify-between pt-4">
          <ButtonPrimary1
            onClick={() => {
              data.clear();
              navigate("/options/allPlans");
            }}
          >
            Clear
          </ButtonPrimary1>
          <ButtonPrimary onClick={handleSubmit}>Next</ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default NewGrantPage;
