import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../../components/utils";
import { Input, Label } from "../../../components/shared/InputField";
import SearchDropDown from "../../../components/shared/SearchDropdown";
import {
  GrantedBy,
  useGrantDetailsStore,
} from "../../../store/useGrantDetailsStore";
import { useValuationDetails } from "../../../queries/Valuation";
import { Valuation, ValuationSource } from "../../../types/ValuationType";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { Select } from "../../../components/shared/Select";

interface GrantByOptionsProps {
  onStepChange: (e?: number) => void;
  onBackClick: () => void;
}

enum ChangedValue {
  Options = "Options",
  Amount = "Amount",
  Valuation = "Valuation",
  Share = "Share",
}

export const GrantPageCard = ({
  header,
  value,
}: {
  header: string;
  value: string;
}) => (
  <VStack className="p-4 border border-gray-200 rounded-lg shadow-custom">
    <p className="p-1 text-sm font-normal form-label">{header}</p>
    <p className="px-12 text-base font-normal text-center">{value}</p>
  </VStack>
);

const GrantByOptionsPage = (props: GrantByOptionsProps) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const data = useGrantDetailsStore();
  const { data: _valuationData, isFetching } = useValuationDetails();
  const [valuationData, setValuationData] = useState<Valuation[]>([]);
  const [errors, setErrors] = useState<any>({});
  useEffect(() => {
    if (_valuationData && _valuationData.length > 0) {
      const companyValuations = _valuationData.filter(
        (v) => v.valuationSource === ValuationSource.COMPANY
      );
      setValuationData([...companyValuations]);
    }
  }, [isFetching]);

  function handleChange(value: number, changedValue: ChangedValue) {
    if (changedValue === ChangedValue.Options) {
      data.setOptionsGranted(value);
      if (value >= 0 && (data.sharePrice || 0) >= 0) {
        data.setValueOfOptions(value * (data.sharePrice || 0));
      }
    } else if (changedValue === ChangedValue.Amount) {
      data.setAmount(value);
      if (data.amount >= 0 && data.sharePrice >= 0) {
        data.setOptionsGranted(
          data?.plan.isFractional
            ? parseFloat((value / data.sharePrice).toFixed(4))
            : parseInt((value / data.sharePrice).toString(), 10)
        );
      }
    } else if (changedValue === ChangedValue.Share) {
      data.setSharePrice(value);
      if (
        value >= 0 &&
        (data.optionsGranted || 0) >= 0 &&
        data.grantedBy === GrantedBy.OPTIONS
      ) {
        data.setValueOfOptions(data.optionsGranted * (value || 0));
      }
      if (
        value >= 0 &&
        (data.amount || 0) >= 0 &&
        data.grantedBy === GrantedBy.AMOUNT
      ) {
        data.setOptionsGranted(
          data?.plan.isFractional
            ? parseFloat((value === 0 ? 0 : data.amount / value).toFixed(4))
            : parseInt((value === 0 ? 0 : data.amount / value).toString(), 10)
        );
      }
    }
  }

  const validationSchema = Yup.object().shape({
    noOfOptions: Yup.number()
      .required("No of Options is required")
      .min(
        data?.plan?.isFractional ? 0.0001 : 1,
        `No of Options must be greater than ${
          data?.plan?.isFractional ? 0.0001 : 1
        }`
      )
      .max(
        data?.plan?.optionsReserved,
        "No. of Options must be lesser than the Options Available"
      ),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  function handleSubmit() {
    formik
      .validateForm({
        noOfOptions: data.optionsGranted,
        grantedBy: data.grantedBy,
      })
      .then((validationErrors) => {
        if (_.isEmpty(validationErrors)) {
          props.onStepChange();
        } else {
          setErrors(validationErrors);
        }
      });
  }

  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Grant Information
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro
          blanditiis doloribus, aut itaque quibusdam aliquam.
        </HStack>
      </VStack>
      <HStack className="gap-4 p-4">
        <GrantPageCard
          header="Options Available"
          value={(data?.plan?.optionsReserved || 0).toLocaleString(
            currencyType
          )}
        />
        <GrantPageCard header="Conversion Ratio" value={data.conversionRatio} />
        <GrantPageCard header="Plan Type" value={data?.plan?.planType} />
      </HStack>
      <VStack className="gap-4 px-6">
        <HStack></HStack>
        <HStack className="items-center justify-between gap-8 pr-40 text-center"></HStack>
        <HStack className="gap-8 ">
          {data.grantedBy === GrantedBy.OPTIONS && (
            <div className="flex-1">
              <Label className="text-sm font-normal">No. of Options</Label>
              <Input
                type="number"
                onChange={(e) => {
                  const value = data?.plan.isFractional
                    ? parseFloat(Number(e.target.value).toFixed(4))
                    : parseInt(e.target.value, 10);
                  handleChange(value, ChangedValue.Options);
                }}
                value={data.optionsGranted}
              />
              {errors.noOfOptions && <Error text={errors.noOfOptions} />}
            </div>
          )}
          {data.grantedBy === GrantedBy.AMOUNT && (
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Grant Amount ({currencySymbol})
              </Label>
              <Input
                type="number"
                onChange={(e) => {
                  const value = parseFloat(Number(e.target.value).toFixed(4));
                  handleChange(value, ChangedValue.Amount);
                }}
                value={data.amount}
              />
            </div>
          )}
          <div className="flex-1"></div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Reports</Label>
            <Select
              onChange={(e) => {
                const valuation = valuationData.find(
                  (v) => v.id === e.target.value
                );
                data.setValuationId(e.target.value);
                handleChange(valuation?.pps || 0, ChangedValue.Share);
              }}
              placeholder="--Select--"
              options={valuationData}
              value={data.valuationId}
              textGetter={(e) => e.name}
              valueGetter={(e) => e.id}
              className={""}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Share Price ({currencySymbol})
            </Label>
            <Input
              type="number"
              disabled={data.valuationId !== ""}
              onChange={(e) => {
                const value = parseFloat(
                  Number(e.target.value === "" ? "0" : e.target.value).toFixed(
                    4
                  )
                );
                handleChange(value, ChangedValue.Share);
              }}
              value={data.sharePrice}
            />
          </div>
        </HStack>
        <HStack className="gap-8 ">
          {data.grantedBy === GrantedBy.OPTIONS && (
            <>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Value of Options ({currencySymbol})
                </Label>
                <Input
                  type="number"
                  value={data.valueOfOptions}
                  disabled={true}
                />
              </div>
              <div className="flex-1"></div>
            </>
          )}
          {data.grantedBy === GrantedBy.AMOUNT && (
            <>
              <div className="flex-1">
                <Label className="text-sm font-normal">No. of Options</Label>
                <Input
                  type="number"
                  onChange={(e) => {}}
                  disabled={true}
                  value={data.optionsGranted}
                />
                {errors.noOfOptions && <Error text={errors.noOfOptions} />}
              </div>
              <div className="flex-1"></div>
            </>
          )}
        </HStack>

        <HStack className="justify-between pt-4">
          <ButtonPrimary1 onClick={props.onBackClick}>Back</ButtonPrimary1>
          <ButtonPrimary onClick={handleSubmit}>Next</ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default GrantByOptionsPage;
