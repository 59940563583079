import React from "react";
import {
  GrantedBy,
  useGrantDetailsStore,
} from "../../../store/useGrantDetailsStore";
import GrantByOptionsPage from "./GrantByOptionsPage";
import AutoGrantPage from "./AutoGrantPage";

interface GrantInformationProps {
  onStepChange: (e?: number) => void;
  onBackClick: () => void;
}

const GrantInformationPage = (props: GrantInformationProps) => {
  const data = useGrantDetailsStore();
  const { onStepChange, onBackClick } = props;
  return (
    <>
      {data.grantedBy !== GrantedBy.MODEL && (
        <GrantByOptionsPage
          onStepChange={onStepChange}
          onBackClick={onBackClick}
        />
      )}

      {data.grantedBy === GrantedBy.MODEL && (
        <AutoGrantPage onStepChange={onStepChange} onBackClick={onBackClick} />
      )}
    </>
  );
};

export default GrantInformationPage;
