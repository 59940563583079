export interface OptionBuyBackExportExcelReq {
  heading: string;
  data: any;
  headers: any;
}

export interface Header {
  key: string;
  value: string;
}

export const buyBackHeadingVal: string = "Employees";
export const buyBackHeadersObj: any = {
  employeeName: "EMPLOYEE NAME",
  granted: "GRANTED OPTIONS",
  vested: "VESTED OPTIONS",
  exercised: "EXERCISED OPTIONS",
  eligibility: "ELIGIBILITY",
  willingness: "WILLINGNESS",
  state: "STATUS",
  totalPriceToPay: "TOTAL AMOUNT PAYABLE BY EMPLOYEES",
  totalValueWillingness: "TOTAL CASHOUT AMOUNT",
  perquisiteTaxAmount: "TOTAL TAX",
  netPayout: "NET PAYOUT AMOUNT",
};
export const buyBackHeaderslist: string[] = [
  "employeeName",
  "granted",
  "vested",
  "exercised",
  "eligibility",
  "willingness",
  "state",
  "totalPriceToPay",
  "totalValueWillingness",
  "perquisiteTaxAmount",
  "netPayout",
];
