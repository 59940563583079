import { Bar } from "react-chartjs-2";
import { Box, HStack } from "../../components/utils";
import { useEmployeesDepartment } from "../../queries";
import { optionsJoiningYear } from "../../shared/chart-data";

const JoiningYear = (props: { title: string | number }) => {
  const { data: department } = useEmployeesDepartment();
  const years = department?.yearofJoiningEmployeeCountDtos || [];
  const labels = years.map((year) => year.yearofJoining);
  const yearsData = years.map((year) => year.numberOfEmployees);
  const data = {
    labels,
    datasets: [
      {
        label: "HeadCount ",
        data: yearsData,
        backgroundColor: "#FFA800",
      },
    ],
  };
  return (
    <Box className="bg-white rounded-lg">
      <HStack className="items-center justify-between h-10 m-4">
        <p className="font-medium text-gray-dark">{props.title}</p>
      </HStack>
      <Box>
        <Bar options={optionsJoiningYear} data={data} />
      </Box>
    </Box>
  );
};

export default JoiningYear;
