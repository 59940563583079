import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { MultiSelect, OptionTypes } from "../../components/shared/MultiSelect";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { useEmployeeDepartment } from "../../queries";
import {
  useCreateOptionBuyback,
  useEditLiquidityEvent,
  useGetOptionBuyback,
  useUploadEmployeeOffers,
} from "../../queries/optionBuyback";
import {
  EventType,
  LiquidityState,
  MonetaryEventState,
  MonetaryTransactionType,
  OptionBuyBackDetails,
  RecurringIntervalUnit,
} from "../../types/OptionBuyback";
import convertToBase64 from "../../utils/convertToBase64";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import { useQuery } from "../../utils/useQuery";
import StatusLabel from "../esopOverview/StatusLabel";
import { EventParticipationConstraints } from "./CreateBuybackDetails";

function CreateEmployeesList() {
  const isUserEsopViewer = isEsopViewer();
  const currencySymbol = getCurrencySymbol();
  const { pathname } = useLocation();
  const { id } = useParams();
  const _id = id ?? "";
  const query = useQuery();
  const searchParam = query.get("mode");
  const navigate = useNavigate();
  const { data: departments } = useEmployeeDepartment();
  const deprt: OptionTypes[] = [];
  (departments || []).forEach((department) => {
    deprt.push({ value: department });
  });

  const { data: optionBuybackOverview } = useGetOptionBuyback();
  let optionBuybackList: OptionBuyBackDetails[] = [];
  const optionBuyback = optionBuybackOverview?.filter(
    (list) => (list?.id || "") === _id
  );
  optionBuybackList = optionBuyback || [];
  const [bcTitle, setBCTitle] = useState<string>();
  useEffect(() => {
    if (pathname.includes("create-exercise-liquidation-flow")) {
      if (searchParam === "create") {
        setBCTitle("Create Liquidity Program");
      } else {
        setBCTitle("Edit Liquidity Program");
      }
    } else if (pathname.includes("create-exercise-flow")) {
      if (searchParam === "create") {
        setBCTitle("Create Exercise Program");
      } else {
        setBCTitle("Edit Exercise Program");
      }
    }
  }, [pathname]);

  function getInitialValues() {
    if (searchParam === "edit") {
      return getEditInitialValues();
    } else {
      return getCreateInitialValues();
    }
  }
  function getCreateInitialValues(): OptionBuyBackDetails {
    return {
      id: "",
      eventName: "",
      description: "",
      eventPercentage: 0,
      recurringSetting: {
        isRecurring: false,
        recurringInterval: 0,
        recurringIntervalUnit: RecurringIntervalUnit.MONTH,
      },
      askWillingness: false,
      buyerName: "",
      createdAt: formatDate(new Date().toString()),
      eventState: MonetaryEventState.DRAFT,
      startDate: formatDate(new Date().toString()),
      endDate: formatDate(new Date().toString()),
      eventType: EventType.CASH,
      isDeleted: false,
      isOnAutoPilot: false,
      liquidityState: LiquidityState.DRAFT,
      monetaryDetails: {
        fmvPrice: 0,
        miscCharges: 0,
        price: 0,
        sellingPrice: 0,
        paymentLink: "",
        includeStrikePrice: false,
      },
      monetaryOffers: [],
      offerFilter: {
        department: [],
        minimumTenure: 0,
        minimumVestedOptions: 0,
        minimumWillingness: 0,
        minimumWillingnessPercentage: 0,
        participationConstraints: [],
      },
      transactionType: MonetaryTransactionType.BUYBACK,
      updatedAt: formatDate(new Date().toString()),
      vestingCutOffDate: formatDate(new Date().toString()),
    };
  }
  function getEditInitialValues(): OptionBuyBackDetails {
    return {
      id: optionBuybackList[0]?.id,
      companyId: optionBuybackList[0]?.companyId,
      askWillingness: optionBuybackList[0]?.askWillingness,
      eventName: optionBuybackList[0]?.eventName,
      description: optionBuybackList[0]?.description,
      eventPercentage: (optionBuybackList[0]?.eventPercentage || 0) * 100,
      buyerName: optionBuybackList[0]?.buyerName,
      createdAt: optionBuybackList[0].createdAt,
      vestingCutOffDate: formatDate(
        new Date(
          optionBuybackList[0]?.vestingCutOffDate || "1970-01-01"
        ).toString()
      ),
      startDate: formatDate(
        new Date(optionBuybackList[0]?.startDate || "1970-01-01").toString()
      ),
      endDate: formatDate(
        new Date(optionBuybackList[0]?.endDate || "1970-01-01").toString()
      ),
      transactionType: optionBuybackList[0]?.transactionType,
      eventState: optionBuybackList[0]?.eventState,
      eventType: optionBuybackList[0]?.eventType,
      isDeleted: optionBuybackList[0]?.isDeleted,
      isOnAutoPilot: optionBuybackList[0]?.isOnAutoPilot,
      liquidityState: optionBuybackList[0]?.liquidityState,
      monetaryDetails: optionBuybackList[0]?.monetaryDetails,
      monetaryOffers: optionBuybackList[0]?.monetaryOffers,
      offerFilter: optionBuybackList[0]?.offerFilter,
      recurringSetting: optionBuybackList[0]?.recurringSetting,
      updatedAt: optionBuybackList[0]?.updatedAt,
    };
  }
  const validationSchema = Yup.object().shape({
    transactionType: Yup.string(),
    recurringSettings: Yup.object().shape({
      isRecurring: Yup.boolean(),
      recurringInterval: Yup.number(),
      recurringIntervalUnit: Yup.string(),
    }),
    eventName: Yup.string()
      .required("Name required")
      .min(3, "Name should be atleast 3 characters"),
    endDate: Yup.string().required("End date required"),
    eventPercentage: Yup.number()
      .when(["showUpload"], {
        is: false,
        then: Yup.number()
          .required("Percentage required")
          .max(100, "Percentage should be less than 100")
          .positive(),
      })
      .required("Percentage required")
      .max(100, "Percentage should be less than 100"),
    description: Yup.string()
      .required("Description required")
      .min(10, "minimum 10 charcters required"),
    monetaryDetails: Yup.object().shape({
      sellingPrice: Yup.number().when("transactionType", {
        is: "exercise and liquidate",
        then: Yup.number().required("sale price required").positive(),
      }),
      fmvPrice: Yup.number().when("transactionType", {
        is: "exercise and liquidate",
        then: Yup.number().required("fmv price required").positive(),
      }),
    }),
    offerFilter: Yup.object().shape({
      minimumTenure: Yup.number().integer("Only accept integer value"),
      minimumVestedOptions: Yup.number().integer("Only accept integer value"),
      minimumWillingness: Yup.number().integer("Only accepts integer value"),
    }),
  });
  const { mutate: uploadOffers } = useUploadEmployeeOffers();
  const [uploadMessage, setUploadMessage] = useState<string>();
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    const excelFile = { file: base64 };
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      uploadOffers(excelFile, {
        onSuccess: (data) => {
          setOffers(data);
          setUploadMessage("Offer List uploaded successfully");
        },
        onError: () => {},
      });
      // eslint-disable-next-line no-useless-return
      return;
    }
  }
  const { mutate: editLiquidityEvent } = useEditLiquidityEvent();
  const [offers, setOffers] = useState();
  const { mutate: createOptionBuyback, isLoading } = useCreateOptionBuyback();
  function handleSubmit(values: OptionBuyBackDetails) {
    let message = "Liquidity program successfully edited";
    const exerciseFlowDto = { ...values };
    exerciseFlowDto.startDate = formatWithTimeZone(exerciseFlowDto.startDate);
    exerciseFlowDto.endDate = formatWithTimeZone(exerciseFlowDto.endDate);
    //liquidation End date have to see
    // exerciseFlowDto.liquidationEndDate = formatWithTimeZone(
    //   exerciseFlowDto.liquidationEndDate || "9999-12-01"
    // );
    exerciseFlowDto.vestingCutOffDate = formatWithTimeZone(
      exerciseFlowDto.vestingCutOffDate
    );
    exerciseFlowDto.eventPercentage /= 100;
    exerciseFlowDto.eventState = MonetaryEventState.DRAFT;
    exerciseFlowDto.eventType = EventType.LIQUIDITY;
    exerciseFlowDto.liquidityState = LiquidityState.DRAFT;
    if (!exerciseFlowDto.monetaryDetails.miscCharges) {
      exerciseFlowDto.monetaryDetails.miscCharges = 0;
    }
    exerciseFlowDto.transactionType =
      pathname.split("/")[2] === "create-exercise-flow"
        ? MonetaryTransactionType.EXERCISE
        : MonetaryTransactionType.EXERCISE_LIQUIDITY;
    if (searchParam === "create") {
      message = "Liquidity Program successfully created";
      delete exerciseFlowDto.id;
    }
    if (
      exerciseFlowDto.eventPercentage === 0 &&
      (searchParam === "create" || searchParam === "edit")
    ) {
      exerciseFlowDto.monetaryOffers = offers || [];
    }
    if (searchParam === "edit") {
      editLiquidityEvent(exerciseFlowDto, {
        onSuccess: (resData) => {
          toast(message, {
            type: "success",
            autoClose: 2000,
          });
          navigate(`/options/view-option-buy-back/${resData.id}`);
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 5000 });
        },
      });
      return;
    }
    createOptionBuyback(exerciseFlowDto, {
      onSuccess: (data) => {
        toast("Option exercise successfully cretaed", {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/view-option-buy-back/${data.id}`);
      },
      onError: (err) => {},
    });
  }

  const [showUpload, setShowUpload] = useState(false);
  useEffect(() => {
    if (optionBuybackList[0]?.eventPercentage <= 0 && searchParam === "edit") {
      setShowUpload(true);
    }
  }, [optionBuybackList]);
  const { isMobile } = useIsMobile();
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formik) => (
          <Form>
            <Box
              className={`flex ${
                isMobile
                  ? "flex-col"
                  : "flex-row justify-between gap-12  items-center "
              }  p-4 bg-white border-b border-solid rounded`}
            >
              <Box className="flex flex-row items-center">
                <h6
                  className={`${
                    isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                  }  font-semibold text-gray-600`}
                >
                  {bcTitle}
                </h6>
                <div className="items-center px-6 py-1 rounded">
                  <StatusLabel
                    state={
                      optionBuybackList[0]?.eventState ||
                      MonetaryEventState.DRAFT
                    }
                  />
                </div>
              </Box>
            </Box>
            <VStack className="gap-4 rounded">
              <VStack className="gap-8 p-4 bg-white rounded-md">
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between gap-12"
                  } items-center`}
                >
                  <VStack className="w-full">
                    <Label>Liquidity Program Name</Label>
                    <Input type="text" {...formik.getFieldProps("eventName")} />
                    {formik.touched.eventName && formik.errors.eventName && (
                      <Error text={formik.errors.eventName} />
                    )}
                  </VStack>
                  <VStack className="w-full">
                    <Label>Description</Label>
                    <Input
                      type="text"
                      {...formik.getFieldProps("description")}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <Error text={formik.errors.description} />
                      )}
                  </VStack>
                </Box>
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between gap-12"
                  } `}
                >
                  <VStack className="w-full">
                    <Label>Start Date</Label>
                    <Input
                      type="date"
                      {...formik.getFieldProps("startDate")}
                      min={format(new Date(), "yyyy-MM-dd")}
                    />
                    {formik.touched.startDate && formik.errors.startDate && (
                      <Error text={formik.errors.startDate} />
                    )}
                  </VStack>
                  <VStack className="w-full">
                    <Label>End Date</Label>
                    <Input
                      type="date"
                      {...formik.getFieldProps("endDate")}
                      min={formik.values.startDate}
                    />
                    {formik.touched.endDate && formik.errors.endDate && (
                      <Error text={formik.errors.endDate} />
                    )}
                  </VStack>
                </Box>
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between items-center gap-12"
                  } `}
                >
                  <VStack className="w-full">
                    <Label>Sale Price ({currencySymbol})</Label>
                    <Input
                      type="number"
                      placeholder="Enter sale price"
                      {...formik.getFieldProps("monetaryDetails.sellingPrice")}
                    />
                    {formik.touched.monetaryDetails?.sellingPrice &&
                      formik.errors.monetaryDetails?.sellingPrice && (
                        <Error
                          text={formik.errors.monetaryDetails?.sellingPrice}
                        />
                      )}
                  </VStack>
                  <VStack className="w-full">
                    <Label>
                      Fair Market Value(FMV) Price ({currencySymbol})
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter FMV price"
                      {...formik.getFieldProps("monetaryDetails.fmvPrice")}
                    />
                    {formik.touched.monetaryDetails?.fmvPrice &&
                      formik.errors.monetaryDetails?.fmvPrice && (
                        <Error text={formik.errors.monetaryDetails?.fmvPrice} />
                      )}
                  </VStack>
                </Box>

                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between items-center gap-12"
                  } `}
                >
                  <VStack className="w-full">
                    <Label>
                      Miscellaneous Charges({currencySymbol}) (Optional)
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter additional charges"
                      {...formik.getFieldProps("monetaryDetails.miscCharges")}
                    />
                    {formik.touched.monetaryDetails?.miscCharges &&
                      formik.errors.monetaryDetails?.miscCharges && (
                        <Error
                          text={formik.errors.monetaryDetails?.miscCharges}
                        />
                      )}
                  </VStack>
                  <VStack className="w-full">
                    <Label>Payment Link (Optional)</Label>
                    <Input
                      type="text"
                      placeholder="Enter Payment Link"
                      {...formik.getFieldProps("monetaryDetails.paymentLink")}
                    />
                    {formik.touched.monetaryDetails?.paymentLink &&
                      formik.errors.monetaryDetails?.paymentLink && (
                        <Error
                          text={formik.errors.monetaryDetails?.paymentLink}
                        />
                      )}
                  </VStack>
                </Box>
              </VStack>
              <VStack className="bg-white rounded-md ">
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between gap-12"
                  } border-b border-solid p-4 font-semibold text-xs text-gray-600`}
                >
                  <h6
                    className={`${
                      isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                    }  font-semibold text-gray-600"`}
                  >
                    Employee-Related filters{" "}
                  </h6>
                </Box>
                <VStack className="gap-8 p-4 ">
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } `}
                  >
                    <VStack className="w-full">
                      <Label>Percentage of vested options</Label>
                      <Input
                        type="number"
                        {...formik.getFieldProps("eventPercentage")}
                      />
                      {formik.touched.eventPercentage &&
                        formik.errors.eventPercentage && (
                          <Error text={formik.errors.eventPercentage} />
                        )}
                    </VStack>
                    <VStack className="w-full">
                      <Label>Vesting Cut Off Date </Label>
                      <Input
                        type="date"
                        max={formik.values.startDate}
                        {...formik.getFieldProps("vestingCutOffDate")}
                      />
                      {formik.touched.vestingCutOffDate &&
                        formik.errors.vestingCutOffDate && (
                          <Error text={formik.errors.vestingCutOffDate} />
                        )}
                    </VStack>
                  </Box>
                  <HStack className="justify-start w-1/2 ">
                    <HStack className="flex-row-reverse items-center justify-end w-1/2 gap-4">
                      <SwitchButton
                        value={formik.values.askWillingness}
                        className="font-medium text-gray-dark"
                        label={"Ask Willingness"}
                        onClick={() => {
                          formik.setFieldValue(
                            "askWillingness",
                            !formik.values.askWillingness
                          );
                        }}
                      />
                    </HStack>
                  </HStack>
                  <HStack className="items-center gap-2 before:border-b-2 before:border-dashed before:m-auto after:flex-1 after:border-dashed after:content-[''] after:border-b-2">
                    <ButtonPrimary1
                      onClick={() => {
                        setShowAdvanceFilters(!showAdvanceFilters);
                      }}
                      className="bg-transparent border border-solid cursor-pointer border-orange-501 hover:bg-transparent"
                    >
                      Advance filters
                    </ButtonPrimary1>
                  </HStack>
                  {showAdvanceFilters && (
                    <VStack className="gap-6">
                      <Box
                        className={` flex ${
                          isMobile
                            ? " flex-col gap-4"
                            : "flex-row  justify-between gap-12"
                        } `}
                      >
                        <VStack className="w-full">
                          <Label>Department (Optional)</Label>
                          <MultiSelect
                            _className="w-full"
                            multiple={true}
                            placeholder={"--Select departments--"}
                            options={deprt || []}
                            handleSelection={(e) => {
                              formik.setFieldValue("offerFilter.department", e);
                            }}
                            optionValues={
                              formik.values.offerFilter.department || []
                            }
                          />
                          {formik.touched.offerFilter?.department &&
                            formik.errors.offerFilter?.department && (
                              <Error
                                text={formik.errors.offerFilter?.department.toString()}
                              />
                            )}
                        </VStack>
                        <VStack className="w-full">
                          <Label>
                            Minimum Employment Tenure (in Years) (Optional)
                          </Label>
                          <Input
                            type="number"
                            {...formik.getFieldProps(
                              "offerFilter.minimumTenure"
                            )}
                          />
                          {formik.touched.offerFilter?.minimumTenure &&
                            formik.errors.offerFilter?.minimumTenure && (
                              <Error
                                text={formik.errors.offerFilter?.minimumTenure}
                              />
                            )}
                        </VStack>
                      </Box>
                      <Box
                        className={` flex ${
                          isMobile
                            ? " flex-col gap-4"
                            : "flex-row  justify-between gap-12"
                        } `}
                      >
                        <VStack className="w-full">
                          <Label>Minimum Eligible Options (Optional)</Label>
                          <Input
                            type="number"
                            {...formik.getFieldProps(
                              "offerFilter.minimumVestedOptions"
                            )}
                          />
                          {formik.touched.offerFilter?.minimumVestedOptions &&
                            formik.errors.offerFilter?.minimumVestedOptions && (
                              <Error
                                text={
                                  formik.errors.offerFilter
                                    ?.minimumVestedOptions
                                }
                              />
                            )}
                        </VStack>

                        <VStack className="w-full">
                          <Label>Minimum Willingness (Optional)</Label>
                          <Input
                            type="number"
                            {...formik.getFieldProps(
                              "offerFilter.minimumWillingness"
                            )}
                          />
                          {formik.touched.offerFilter?.minimumWillingness &&
                            formik.errors.offerFilter?.minimumWillingness && (
                              <Error
                                text={
                                  formik.errors.offerFilter?.minimumWillingness
                                }
                              />
                            )}
                        </VStack>
                      </Box>
                      <EventParticipationConstraints />
                    </VStack>
                  )}
                  {!isUserEsopViewer && (
                    <HStack
                      className={`${
                        isMobile ? "justify-center" : "justify-end"
                      }`}
                    >
                      <ButtonPrimary type="submit">
                        {!isLoading ? (
                          searchParam === "create" ? (
                            "Generate Offer List"
                          ) : (
                            "Save"
                          )
                        ) : (
                          <HStack>
                            <div>Please Wait...</div>

                            <Icon
                              className="animate-spin"
                              icon="lucide:loader-2"
                              width={18}
                              height={18}
                            />
                          </HStack>
                        )}
                      </ButtonPrimary>
                    </HStack>
                  )}
                </VStack>
              </VStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateEmployeesList;
