import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  Box,
  ButtonPrimary,
  HStack,
  isAdminViewer,
  isEsopViewer,
  VStack,
} from "../../components/utils";
import { useEsopPlans, useGrants } from "../../queries";
import BCHeader from "../../shared/BCHeader";
import CardDraft from "../../shared/card-draft";
import CardPend from "../../shared/card-pend";
import CardPool from "../../shared/card-pool";
import PendingRequests from "../../shared/PendingRequests";
import useIsMobile from "../../utils/detectDevice";
import { Dropdown } from "../../utils/interfaces/BCHeader";
import GrantsTable from "../esopOverview/GrantsTable";
import ESOPPlan from "./ESOPPlan";
import SearchAutoComplete from "../../components/shared/SearchAutoComplete";

const SinglePlanView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const _id = id ?? "";
  const { data: _grants } = useGrants();
  const { data: _plans, isFetching } = useEsopPlans();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  const singlePlan = _plans?.filter((plan) => plan.esopPlanId === _id) || [];
  const planFilterName: Dropdown[] = [];
  _plans?.forEach((plan) => {
    planFilterName.push({
      name: plan.planName,
      id: plan.esopPlanId,
    });
  });
  const [selectedPlan, setSelectedPlan] = useState<Dropdown | undefined>();
  const initialFilterValue = useMemo(() => {
    const selectedPlanFilter = planFilterName.filter(
      (plan) => plan.id === _id
    )[0];
    return selectedPlanFilter;
  }, [_id, _plans]);
  useEffect(() => {
    setSelectedPlan(initialFilterValue);
  }, [initialFilterValue, _id]);
  const handleAction = () => {
    navigate(`/options/plan/edit/${_id}?tab=Summary`);
  };

  const handlePlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    if (id === "Total") {
      navigate("/options/allPlans");
    } else navigate(`/options/planView/${id}`);
  };
  planFilterName.push({ name: "All Plans", id: "Total" });
  const { isMobile } = useIsMobile();
  function handleSelectOptions(selectedPlan: any) {
    if (selectedPlan.id === "Total") {
      navigate("/options/allPlans");
    } else {
      navigate(`/options/planView/${selectedPlan.id}`);
    }
  }
  return (
    <VStack className="gap-4 ">
      <HStack className="justify-between">
        <BCHeader
          className="items-baseline"
          bcTitle="Equity Plans & Grants"
          bcSubTitle={selectedPlan?.name ?? ""}
          onClick={() => handleAction()}
          selectOption={handlePlan}
          dropdown={planFilterName}
          selectedValue={selectedPlan}
        ></BCHeader>

        <Box className={`flex flex-row ${isMobile ? "mb-4 gap-2 " : ""}`}>
          {planFilterName && planFilterName.length > 0 ? (
            <Box className="w-48 h-8 bg-white rounded">
              <SearchAutoComplete
                data={planFilterName}
                getSelectedValue={() => selectedPlan}
                selectedOption={selectedPlan! as Dropdown}
                field={"name"}
                selectOption={(selectedPlan: Dropdown) =>
                  handleSelectOptions(selectedPlan)
                }
              />
            </Box>
          ) : (
            ""
          )}
          <Box>
            <ButtonPrimary
              className={`px-5 py-1 ${isMobile ? "" : "ml-4"} h-8`}
              onClick={handleAction}
              disabled={isUserAdminViewer || isUserEsopViewer}
            >
              Amend Plan
            </ButtonPrimary>
          </Box>
        </Box>
      </HStack>

      <HStack className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-3 md:grid-cols-2">
        <ESOPPlan />
        <CardPool />
        <CardDraft />
      </HStack>
      <HStack className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-2 md:grid-cols-2">
        <PendingRequests />
        <CardPend />
      </HStack>
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
        <GrantsTable />
      </Box>
    </VStack>
  );
};

export default SinglePlanView;
