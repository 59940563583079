import { useMutation, useQuery } from "react-query";
import {
  closeExerciseEvent,
  closeLiquidityEvent,
  completeExerciseEvent,
  completeLiquidityEvent,
  createExerciseEvent,
  createOptionBuybackDetails,
  discardBuyback,
  editExerciseRequestedNumber,
  editLiquidityEvent,
  editPaymentLink,
  editPilotMode,
  getAllApprovedExercises,
  getEventOffers,
  getOptionBuybackDetails,
  pauseExerciseEvent,
  sendLiquidationIntimation,
  startLiquidityEvent,
  uploadEmployeeOffersList,
  uploadTDSExcel,
} from "../api/optionBuyback";
import { CumulativeOffer, OptionBuyBackDetails } from "../types/OptionBuyback";
import { queryClient } from "./client";
import { randomOfferList, randomOptionBuybackOverview } from "./placeholders";

export function useGetOptionBuyback() {
  return useQuery({
    queryKey: "overview",
    queryFn: getOptionBuybackDetails,
    placeholderData: Array(10)
      .fill(0)
      .map((_i) => randomOptionBuybackOverview()),
  });
}

export function useCreateOptionBuyback() {
  return useMutation({
    mutationKey: "creatOptionBuyback",
    mutationFn: createOptionBuybackDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
    },
    onError: () => {},
  });
}

export function useBuybackOffers(buybackId: string) {
  return useQuery({
    queryKey: ["buybackState", buybackId],
    queryFn: getEventOffers,
    enabled: !!buybackId,
    placeholderData: Array(10)
      .fill({})
      .map((_i) => randomOfferList()) as CumulativeOffer[],
  });
}

export function useDiscardBuyback() {
  return useMutation({
    mutationKey: "discardBuyback",
    mutationFn: discardBuyback,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
    },
    onError: () => {},
  });
}

export function useSendLiquidationIntimation() {
  return useMutation({
    mutationKey: "startLiquidation",
    mutationFn: sendLiquidationIntimation,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useUploadEmployeeOffers() {
  return useMutation({
    mutationKey: "uploadEmployeeList",
    mutationFn: uploadEmployeeOffersList,
  });
}

export function useGetUploadedTDS() {
  return useMutation({
    mutationFn: uploadTDSExcel,
    mutationKey: "uploadEmployeeTDSList",
    onSuccess: (data) => {
      queryClient.setQueryData("uploadedTDS", data);
    },
    onError: () => {},
  });
}

export function useGetUploadedTDSList(isFetched: boolean) {
  return useQuery({
    queryKey: "uploadedTDS",
    queryFn: undefined,
    enabled: isFetched,
  });
}

export function useStartLiquidityEvent() {
  return useMutation({
    mutationKey: "StartEvent",
    mutationFn: startLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
    onError: () => {},
  });
}

export function useCompleteLiquidityEvent() {
  return useMutation({
    mutationKey: "completeEvent",
    mutationFn: completeLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
      queryClient.invalidateQueries("documents");
      queryClient.refetchQueries("documents");
    },
  });
}

export function useCloseLiquidityEvent() {
  return useMutation({
    mutationKey: "closeEvent",
    mutationFn: closeLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useEditLiquidityEvent() {
  return useMutation({
    mutationKey: "editEvent",
    mutationFn: editLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useEditPilotMode() {
  return useMutation({
    mutationKey: "pilotAutoMode",
    mutationFn: editPilotMode,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useCreateExerciseEvent() {
  return useMutation({
    mutationKey: "createExerciseEvent",
    mutationFn: createExerciseEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
    },
  });
}

export function useCompleteExerciseEvent() {
  return useMutation({
    mutationKey: "completeExerciseEvent",
    mutationFn: completeExerciseEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
    },
  });
}

export function useGetAllApprovedExercises(eventId: string) {
  return useQuery({
    queryKey: ["getAllApprovedExercises", eventId],
    queryFn: getAllApprovedExercises,
    enabled: false,
  });
}

export function useCloseExerciseEvent() {
  return useMutation({
    mutationKey: "closeExerciseEvent",
    mutationFn: closeExerciseEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
    },
  });
}

export function usePauseExerciseEvent() {
  return useMutation({
    mutationFn: pauseExerciseEvent,
    mutationKey: "pauseExerciseEvent",
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.refetchQueries("overview");
    },
  });
}

export function useEditExerciseOptions() {
  return useMutation({
    mutationFn: editExerciseRequestedNumber,
    mutationKey: "editExerciseRequestedNumber",
    onSuccess: () => {
      queryClient.invalidateQueries("overview");
      queryClient.invalidateQueries("getAllExerciseRequests");
      queryClient.refetchQueries("overview");
      queryClient.refetchQueries("getAllExerciseRequests");
    },
  });
}

export function useEditPaymentLink() {
  return useMutation({
    mutationKey: "buybackState",
    mutationFn: editPaymentLink,
  });
}
