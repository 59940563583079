import { useEffect, useMemo, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import SortingComponent from "../../components/SortingVariation";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import {
  Box,
  ButtonPrimary,
  HStack,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useVestingTemplates } from "../../queries";
import GenericTableHeader from "../../shared/GenericTableHeader";
import { usePermissionStore } from "../../store/permissionStore";
import { useVestingScheduleDialog } from "../../store/useDialogStore";
import { TriggerType, VestingTemplate } from "../../types/VestingTemplate";
import { determineUserAccessToResource } from "../../utils/auth";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import VestingTemplatesTableAgGrid from "./VestingTemplatesTableAgGrid";

function VestingTemplatesTable() {
  const { data: _vestingSchedules, isPlaceholderData } = useVestingTemplates();
  const data =
    useMemo(
      () => _vestingSchedules?.filter((schedule) => !schedule.isDefault),
      [_vestingSchedules]
    ) || [];
  // filter and sort logic
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof VestingTemplate | "" | undefined;
    ascending: boolean;
  }>();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const { permission } = usePermissionStore();

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  const vestingTableRef = useRef<GridApi | null>(null);
  const [vestingTableCount, setVestingTableCount] = useState<number>(
    data.length
  );

  function handleTableFilterCount(data?: boolean) {
    const vestingTableCount = vestingTableRef?.current
      ?.getModel()
      .getRowCount();
    if (vestingTableCount !== undefined) {
      setVestingTableCount(vestingTableCount!);
    }
  }

  useEffect(() => {
    if (vestingTableRef.current) {
      const gridApi = vestingTableRef.current.getModel();
    }
  }, [vestingTableRef, data]);

  useEffect(() => {
    handleTableFilterCount();
  }, [data]);

  // selection logic
  const [selectedItems, setSelectedItems] = useState<VestingTemplate[]>([]);
  const onSelectionChange = (item: VestingTemplate) => {
    setSelectedItems((items) =>
      items.find((i) => i === item)
        ? items.filter((i) => i !== item)
        : [...items, item]
    );
  };
  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, pageSize]);

  // Actions
  const { setState: setDialog } = useVestingScheduleDialog();

  function handleAction(template: VestingTemplate, action: Action) {
    if (action.name === "Edit" && !action.disabled) {
      setDialog({ open: true, template, mode: "Edit" });
    }
  }
  const vestingTriggerType = [
    {
      name: "Time",
      value: TriggerType.TIME,
    },
    {
      name: "Event",
      value: TriggerType.EVENT,
    },
    {
      name: "Both",
      value: TriggerType.BOTH,
    },
  ];
  const { isMobile } = useIsMobile();
  return (
    <VStack className={`${isPlaceholderData ? "loading" : ""}`}>
      <Box className="p-4 bg-white border rounded-lg min-w-min border-borderColor drop-shadow-box ">
        <Box
          aria-label="toolbar"
          className={` flex flex-row justify-between
          mb-2`}
        >
          <VStack className="min-w-max">
            <GenericTableHeader
              heading={"Vesting Timelines"}
              additionalInfo={<>{vestingTableCount} Vesting Schedules</>}
              subHeading={""}
            ></GenericTableHeader>
          </VStack>
          <HStack className="h-8 min-w-min">
            <ButtonPrimary
              className={
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.VestingSchedule,
                  DefaultAction.Create
                )
                  ? "opacity-50 cursor-not-allowed min-w-max"
                  : "min-w-max"
              }
              disabled={
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.VestingSchedule,
                  DefaultAction.Create
                )
              }
              onClick={() => setDialog({ open: true, mode: "Add" })}
            >
              Add Vesting Template
            </ButtonPrimary>
          </HStack>
        </Box>

        <HStack className="justify-between w-full ">
          <VestingTemplatesTableAgGrid
            key={"VestingTemplatesTable"}
            VestingTemplateTableData={data}
            handleAction={handleAction}
            vestingTableRef={vestingTableRef}
            tableFilterCount={handleTableFilterCount}
          />
        </HStack>
        <HStack className="justify-between w-full">
          <Box
            // style={{ height: `${pageSize * 64 + 128}px` }}
            className="hidden w-full max-h-full overflow-x-auto"
          >
            <table
              className={` table-space ${
                isMobile ? "min-w-[1040px]" : "w-full"
              }`}
            >
              <thead className="text-xs font-medium text-gray-light">
                <tr className="">
                  <td>
                    <HStack className="items-center hover:cursor-pointer">
                      TEMPLATE NAME
                      <SortingComponent
                        fieldName="vestingTemplateName"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "vestingTemplateName",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                  <td>
                    <HStack className="items-center hover:cursor-pointer">
                      OPTION HOLDERS
                      <SortingComponent
                        fieldName="numberOfGrantsAffected"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "numberOfGrantsAffected",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                  <td>
                    <HStack className="items-center hover:cursor-pointer">
                      VESTING TYPE
                      <SortingComponent
                        fieldName="vestingType"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "vestingType",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                  <td className="py-2 align-middle">VESTING SCHEDULES</td>
                  <td className="py-2 align-middle"></td>
                </tr>
              </thead>
              <tbody className={isPlaceholderData ? "loading" : ""}>
                {currentTableData &&
                  currentTableData?.map((template) => (
                    <tr key={template.id} className="border-t border-dashed ">
                      <td className="py-2 align-middle">
                        <HStack className="">
                          <Box
                            className={`cursor-pointer ${
                              isUserAdminViewer || isUserEsopViewer
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                            onClick={() =>
                              !(isUserAdminViewer || isUserEsopViewer)
                                ? setDialog({
                                    open: true,
                                    template,
                                    mode: "View",
                                  })
                                : null
                            }
                          >
                            <p
                              className={`text-xs font-medium text-gray-dark `}
                            >
                              {template.vestingTemplateName}
                            </p>
                          </Box>
                        </HStack>
                      </td>
                      <td className="py-2 align-middle">
                        <Box className="">
                          <HStack>
                            <p className={`text-xs font-medium text-gray-dark`}>
                              {template.numberOfGrantsAffected}
                            </p>
                          </HStack>
                        </Box>
                      </td>
                      <td className="py-2 align-middle">
                        <HStack>
                          <p className={`text-xs font-medium text-gray-dark`}>
                            {template.vestingType}
                          </p>
                        </HStack>
                      </td>
                      <td className="py-2 align-middle">
                        <CTADropdown
                          actions={[
                            {
                              name: "Edit",
                              disabled:
                                template.numberOfGrantsAffected > 0 ||
                                isUserAdminViewer ||
                                isUserEsopViewer ||
                                !determineUserAccessToResource(
                                  permission?.aclList || [],
                                  Resource.VestingSchedule,
                                  DefaultAction.Create
                                ),
                            },
                          ]}
                          onAction={(action) => handleAction(template, action)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Box className="flex justify-between mt-8">
              <BasicMenu
                defaultValue={pageSize}
                options={[5, 10, 20, 50, 100]}
                onOptionChange={(value) => {
                  setCurrentPage(1);
                  setPageSize(value);
                }}
              />
              <Pagination
                className=""
                currentPage={currentPage}
                totalCount={data.length}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </Box>
          </Box>
        </HStack>
      </Box>
    </VStack>
  );
}

export default VestingTemplatesTable;
