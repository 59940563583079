import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import randomColor from "randomcolor";
import { useNavigate } from "react-router";
import { Box, HStack, VStack } from "../components/utils";
import { useEsopPlans } from "../queries";
import { PlanState } from "../types/EsopPlan";
import { getCurrencyType } from "../utils/currencyFormatter";
import { getFormattedValue } from "../utils/string";
import { DoughnutChart } from "./DoughnutChart";

ChartJS.register(ArcElement, Tooltip, Legend);

const CardPlan = () => {
  const currency = getCurrencyType();
  const { data, isPlaceholderData } = useEsopPlans();
  const navigate = useNavigate();

  const placeholderData = [
    {
      planName: "Placeholder Plan 1",
      esopPlanState: PlanState.ACTIVE,
      isFractional: true,
      issuedShares: 0,
      esopPlanId: "placeholder-1",
      optionsReserved: 0,
      poolSize: 0,
    },
    {
      planName: "Placeholder Plan 2",
      esopPlanState: PlanState.ACTIVE,
      isFractional: true,
      issuedShares: 0,
      esopPlanId: "placeholder-2",
      optionsReserved: 0,
      poolSize: 0,
    },
    {
      planName: "Placeholder Plan 3",
      esopPlanState: PlanState.ACTIVE,
      isFractional: true,
      issuedShares: 0,
      esopPlanId: "placeholder-3",
      optionsReserved: 0,
      poolSize: 0,
    },
  ];

  const plans = isPlaceholderData
    ? placeholderData
    : (data || []).filter(
        (plan) =>
          plan.planName !== "Total" && plan.esopPlanState === PlanState.ACTIVE
      );

  const isFractional = plans.some((plan) => plan.isFractional);
  const header = "Option Plans";

  const values = plans.map((plan) => [
    isFractional ? parseFloat(plan.issuedShares.toFixed(4)) : plan.issuedShares,
    plan.planName,
  ]);
  const graphValues = values.map((value) => value[0]);
  const planNames = values.map((value) => value[1]);

  const backgroundColor = planNames.map((name) =>
    randomColor({
      luminosity: "bright",
      alpha: 0.3,
      seed: name,
    })
  );

  const planData = {
    labels: planNames,
    datasets: [
      {
        label: "# of Votes",
        data: graphValues,
        backgroundColor,
        borderColor: backgroundColor,
      },
    ],
  };

  return (
    <VStack
      className={`p-6 bg-white rounded-lg max-h-96 overflow-auto border border-borderColor drop-shadow-box ${
        isPlaceholderData ? "loading" : ""
      }`}
    >
      <VStack className="mb-4">
        <p className="font-medium text-gray-dark">{header}</p>
        <p className="text-xs1 font-semi-medium text-gray-light">
          {plans.length.toLocaleString(currency)} Active Plans
        </p>
      </VStack>

      {!isPlaceholderData && (
        <Box
          className="cursor-pointer w-50 h-70 align-center"
          onClick={() => {
            navigate("/options/allPlans");
          }}
        >
          <DoughnutChart data={planData} />
        </Box>
      )}

      <VStack className="overflow-auto">
        {plans.map((plan) => {
          const backgroundColor = randomColor({
            luminosity: "bright",
            alpha: 0.3,
            seed: plan.planName,
          });
          return (
            <HStack
              key={plan.esopPlanId.toString()}
              className="justify-between p-2"
            >
              <HStack>
                <Box
                  style={{ backgroundColor }}
                  className="w-4 h-4 mt-2 rounded"
                ></Box>
                <VStack
                  className="pl-4 cursor-pointer"
                  onClick={() => {
                    navigate(`/options/planView/${plan.esopPlanId}`);
                  }}
                >
                  <p className="font-medium text-xs2 text-gray-dark">
                    {plan.planName}
                  </p>
                  <p className="text-xs1 font-semi-medium text-gray-light">
                    {getFormattedValue(
                      (plan?.poolSize || 0) - (plan?.optionsReserved || 0),
                      currency,
                      isFractional
                    )}{" "}
                    Options Granted
                  </p>
                </VStack>
              </HStack>
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default CardPlan;
